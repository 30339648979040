// For AppTrove

export class DocumentsMetadataResponse {
    getDocumentMetadata: {
        documentGroup: DocumentGroup[]
    }
}

export class DocumentGroup {
    policyNumber: string;
    hasPaperlessDocs: boolean;
    primaryDocuments: DocumentMetadata[];
    priorDocuments: DocumentMetadata[];
}

export class DocumentMetadata {
    billingAccountNumber: string;
    lineOfBusiness: string;
    policyEffectiveDate: string;
    policyExpirationDate: string;
    transactionEffectiveDate: string;
    policyNumber: string;
    creationDate: string;
    docTypeCode: string;
    jurisdiction: string;
    effectiveDate:string;
    displayName: string;
    docTypeName: string;
    objectId: string;
    documentUID: string;
    newDocument: boolean;
    currentDocument: boolean;
    contentUrl: string;
    documentType: string;
    receivedPassDate: string;
    documentTypeDescription: string;
}

export type DocumentVariables = {
    policyNumbers: string[];
    category: DocCategory;
}

export enum DocCategory {
    policy = "Policy",
    billing = "Billing",
}

export class DocumentUrlResponse {
    getDocumentURL: {
        downloadUrl: string;
    }
}

export type DocumentUrlVariables = {
    documentId: string;
    tenant: string;
    category: DocCategory;
}