import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { environment as env} from 'src/environments/environment';

const maxAge = env.cacheDuration;

@Injectable()
export class RequestCache  {

  cache = new Map();

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const cached = this.cache.get(req.urlWithParams);
    
    if (!cached) {
      return undefined;
    } else {
      cached.response = new HttpResponse(cached.response);
      const isExpired = cached.lastRead < (Date.now() - maxAge);
      if (isExpired) {
        return undefined;
      }
    }

    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.url;
    const entry = { url, response, lastRead: Date.now() };
    if (!url.includes('graphql')) {
      this.cache.set(url, entry);
    }
    
    const expired = Date.now() - maxAge;
    this.cache.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }

  cacheBust(cacheBustUrl?: string) {
    this.cache.forEach(entry => {
      if (entry.url === cacheBustUrl || !cacheBustUrl) {
        this.cache.delete(entry.url);
      }
    });
  }
}
