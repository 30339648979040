import { gql } from 'apollo-angular';
import { CrossSellRs, CrossSellVariables, DispositionRs, DispositionVariables } from '../models/crossSellOffer';


export const GET_X_SELL_OFFERS = gql<CrossSellRs, CrossSellVariables> `query
Recommendations(
  $userId: String!
  $customerId: String!
  $recommendationRequests: [RecommendationRequest!]!
  $policyId: String
) {
  recommendations(
    carrierCode: Safeco
    userId: $userId
    customerId: $customerId
    recommendationRequests: $recommendationRequests
    policyId: $policyId
  ) {
    recommend
    name
    id
    applicableEntityId
    applicableEntityType
    ... on SellAutoPolicy {
      score
    }
    ... on SellHomePolicy {
      score
    }
    ... on SellRentersPolicy {
      score
    }
    ... on SellEarthquakePolicy {
      score
    }
    ... on SellUmbrellaPolicy {
      score
    }
    ... on SellMotorcyclePolicy {
      score
    }
    ... on SellWatercraftPolicy {
      score
    }
  }
}`;

  
export const SEND_X_SELL_DISPOSITION = gql<DispositionRs, DispositionVariables> `mutation 
AddDisposition($addDispositionDto: AddDispositionRequestDto!) {
  addDisposition(addDispositionDto: $addDispositionDto) {
    addedDisposition {
      id
      value
      customerDisposition
      insertDateTime
    }
  }
}`;
