import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Apollo, MutationResult } from 'apollo-angular';

import CrossSellOffer, { CrossSellPolicy, CrossSellAgency, CrossSellRs, CrossSellOffers, DispositionRs, Dispositions, CrossSellCustomer } from 'src/app/utilities/models/crossSellOffer';
import PolicyMethods from '../../methods/policy.methods';
import { PolicyService } from '../policy-service/policy.service';
import CAMStorage from '../../constants/CAMStorage';
import { AccountService } from '../account-service/account.service';
import { GET_X_SELL_OFFERS, SEND_X_SELL_DISPOSITION } from '../../graphql/troveXSell.queries';


@Injectable({
  providedIn: 'root'
})
export class CrossSellService {

  httpOptionsSimple = {
    withCredentials: true
  };

  constructor(
    private apollo: Apollo,
    private commonService: CommonService,
    private location: Location,
    private policyService: PolicyService,
    private accountService: AccountService,
  ) { 
    
  }

  async getCrossSellOfferInfo() {
    const crossSellOffer = new CrossSellOffer();

    try {
      CAMStorage.removeItemInStorage(CAMStorage.storageKeys.crossSellStorage);

      const allPolicies = await this.policyService.getAllPolicies();
      const policies = this.policyService.filterExistingPolicies(allPolicies.policies);
      const xSellPolicyArray: CrossSellPolicy[] = PolicyMethods.buildCrossSellPolicies(policies);
      if (xSellPolicyArray?.length) {
        const xSellRs = await this.getCrossSellOfferQL(xSellPolicyArray[0]);
        crossSellOffer.recommendations = xSellRs.recommendations;
      }

      const saveCustomerRecommendation = crossSellOffer.recommendations.find((recommendation) => recommendation.name === CrossSellOffers.SAVE_CUSTOMER);
      crossSellOffer.saveCustomer = saveCustomerRecommendation;
      crossSellOffer.recommendations = crossSellOffer.recommendations.filter((recommendation) => recommendation.name !== CrossSellOffers.SAVE_CUSTOMER);

      // sorting the recommendations by highest score and then find the first recommended offer
      crossSellOffer.recommendations.sort((a, b) => b.score - a.score);
      const recommendedOffer = crossSellOffer?.recommendations.find((recommendation) => recommendation.recommend);

      // verify if a recommended offer is available and customer can be saved
      crossSellOffer.offerAvailable = recommendedOffer?.recommend;
      
      if (crossSellOffer.offerAvailable) {
        crossSellOffer.lobOffer = recommendedOffer.name.replace('SELL_', '').replace('_POLICY', '');
        crossSellOffer.recommendationId = recommendedOffer.id;
        crossSellOffer.sourceLob = xSellPolicyArray[0].lineOfBusiness;
        crossSellOffer.savings = this.setOfferSavings(crossSellOffer.lobOffer, xSellPolicyArray[0]?.ratingState);

        const primaryAgency = await this.policyService.getPrimaryAgency();
        crossSellOffer.agency = new CrossSellAgency();
        if (primaryAgency) {
          crossSellOffer.agency.agencyName = primaryAgency.agencyName;
          crossSellOffer.agency.agencyPhone = primaryAgency.phoneNumber;
          crossSellOffer.agency.agencyEmail = primaryAgency.emailAddress;
          crossSellOffer.agency.goldAgency = PolicyMethods.isGoldAgency(primaryAgency);
          crossSellOffer.agency.goldPlusAgency = PolicyMethods.isGoldPlusAgency(primaryAgency);
        }

        const authUser = this.accountService.getAuthUser();
        if (authUser) {
          crossSellOffer.customer = new CrossSellCustomer();
          crossSellOffer.user = authUser.userId;
          crossSellOffer.customer.email = authUser.email;
          crossSellOffer.customer.firstName = authUser.firstName;
          crossSellOffer.customer.lastName = authUser.lastName;
          crossSellOffer.customer.phone = authUser.phoneNumber;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      CAMStorage.setItemInStorage(CAMStorage.storageKeys.crossSellStorage, crossSellOffer);
    }
  
    return crossSellOffer;
  }

  private async getCrossSellOfferQL(policy: CrossSellPolicy): Promise<CrossSellRs> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetCrossSellOfferQL';
    const path = this.location.path();
    const authUser = this.accountService.getAuthUser();
  
    return new Promise((resolve, reject) => {
      this.apollo.query({
        query: GET_X_SELL_OFFERS,
        fetchPolicy: 'cache-first',
        variables: {
          policyId: policy.policyNumber,
          customerId: authUser.masterPartyId,
          userId: authUser.userId,
          recommendationRequests: [
          {
            name: CrossSellOffers.SAVE_CUSTOMER
          },
          {
            name: CrossSellOffers.SELL_AUTO_POLICY
          },
          {
            name: CrossSellOffers.SELL_HOME_POLICY
          },
          {
            name: CrossSellOffers.SELL_RENTERS_POLICY
          },
          {
            name: CrossSellOffers.SELL_EARTHQUAKE_POLICY
          },
          {
            name: CrossSellOffers.SELL_UMBRELLA_POLICY
          },
          {
            name: CrossSellOffers.SELL_MOTORCYCLE_POLICY
          },
          {
            name: CrossSellOffers.SELL_WATERCRAFT_POLICY
          }
        ]
      }
      }).subscribe({
        next: (response) => {
          if(response?.data?.recommendations?.length) {
            resolve(response.data);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject ({error: 'graphql response error'});
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject ({error: error});
        }
      });    
    });
  }

  private setOfferSavings(offerLob: string, ratingState: string): number {
    let savings = 0;
    switch (offerLob?.toLowerCase()) {
      case 'auto':
        switch (ratingState?.toUpperCase()) {
          case "AR":
          case "IN":
          case "KS":
          case "KY":
          case "LA":
          case "MS":
          case "OK":
          case "TX":
          case "WV":
            savings = 515;
            break;
          case "AL":
          case "IA":
          case "IL":
          case "MO":
          case "NE":
          case "SD":
          case "TN":
            savings = 414;
            break;
          case "CT":
          case "CO":
          case "GA":
          case "MI":
          case "MD":
          case "MN":
          case "MT":
          case "ND":
          case "VA":
          case "WI":
          case "WY":
            savings = 304;
            break;
          case "AK":
          case "AZ":
          case "CA":
          case "NH":
          case "NM":
          case "NV":
          case "NY":
          case "OH":
          case "PA":
          case "SC":
          case "WA":
            savings = 207;
            break;
          case "ID":
          case "OR":
          case "UT":
          case "VT":
            savings = 155;
            break;
          default:
            break;
        }
        break;
      case 'home':
        switch (ratingState?.toUpperCase()) {
          case "GA":
          case "LA":
          case "MD":
          case "NV":
          case "NY":
          case "WV":
            savings = 225;
            break;
          case "AZ":
          case "CT":
          case "KY":
          case "MI":
          case "MS":
          case "TX":
            savings = 193;
            break;
          case "FL":
          case "KS":
          case "MN":
          case "NM":
          case "OK":
          case "UT":
          case "VA":
          case "WA":
            savings = 180;
            break;
          case "AL":
          case "AR":
          case "CO":
          case "PA":
          case "SC":
          case "TN":
            savings = 170;
            break;
          case "IL":
          case "MO":
          case "MT":
          case "NE":
          case "OR":
          case "SD":
            savings = 159;
            break;
          case "IN":
          case "NC":
          case "ND":
          case "NH":
          case "OH":
          case "WY":
            savings = 138;
            break;
          case "IA":
          case "ID":
          case "ME":
          case "VT":
          case "WI":
            savings = 121;
            break;
          default:
            break;
        }
        break;
      default:
        return savings;
    }

    return savings;
  }


  postCrossSellDispositionQL(dispositionOffer: CrossSellOffer, dispositionEvent: Dispositions): Promise<DispositionRs> {
    const errorHeapId = 'MMA-View-NotificationSystemError|PostCrossSellOffer';
    const path = this.location.path();
    
    return new Promise((resolve, reject) => {
      this.apollo.mutate({
        mutation: SEND_X_SELL_DISPOSITION,
        variables: {
          addDispositionDto: {
            recommendationId: dispositionOffer.recommendationId,
            disposition: dispositionEvent
          }
        },
        fetchPolicy: 'network-only'
      }).subscribe({
        next: (response: MutationResult<DispositionRs>) => {
          if(response?.data?.addDisposition?.addedDisposition) {
            resolve(response.data);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject ({error: 'graphql response error'});
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject ({error: error});
        }
      });
    });
  }
}
