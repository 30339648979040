<div class="modal-component" tabindex="-1">
  <div class="modal-overlay-dark" (click)="checkModalFocus($event)" (keyup.enter)="checkModalFocus($event)" tabindex="0">
    <div class="modal-content" role="dialog"
      id="app_modal" aria-modal="true"
      [ngClass]="{ 'modal-content-sm': isModalSmall, 'scroll-enabled': isScrollEnabled, 'content-only-scroll': isModalContentScrollEnabled, 'mobile-full-screen': isMobileFullScreenEnabled }">
      <div #modalHiddenStart tabindex="0" id="modal_hidden_start"
        style="opacity: 0; height: 0; position: fixed; top: -10px"></div>

      <div class="modal-wrapper">
        <div class="modal-container" *ngIf="modalName === modalNames.ROUTING_ACCT_NUMBER">
          <img class="expand-img" ngSrc="{{imgLoc}}/routing_account_number.svg" width="220" height="125"
            alt="Your routing number is typically nine digits and can be found toward the lower left of your check. Your account number is typically eight to 12 digits and can be found to the right of your routing number.">
        </div>
        <div class="eft-authorization-modal-container modal-container"
          *ngIf="modalName === modalNames.EFT_AUTHORIZATION">
          <div class="modal-title">Automatic bank withdrawal authorization</div>
          <div class="modal-sub-heading">
            By enrolling in autopay with my checking or savings account, I agree to the following
            provisions:
          </div>
          <div class="modal-info-list">
            <ul>
              <li>
                I authorize Safeco Insurance to initiate recurring withdrawals from the bank account I
                have indicated, and I authorize the financial institution ("bank") to accept the
                withdrawals initiated by Safeco.
              </li>
              <li>
                Safeco may withdraw payments from my bank account ON or AFTER the selected monthly
                withdrawal date when payments are due for my Safeco account.
              </li>
              <li>
                Safeco must notify me of the first withdrawal amount and whenever the withdrawal amount
                changes.
              </li>
              <li>
                Notifications, including deduction amount changes and payment confirmations, will be sent electronically
                to the email address on file.
              </li>
              <li>
                I have the right to recover the amount of any erroneous Safeco withdrawal by way of
                refund to my bank account.
              </li>
              <li>
                I have the right to terminate this payment option or change my payment option or bank
                information by notifying Safeco. I understand Safeco requires one week’s notice in order
                to make updates in time for my next withdrawal.
              </li>
            </ul>
          </div>
        </div>
        <div class="cc-authorization-modal-container modal-container" *ngIf="modalName === modalNames.CC_AUTHORIZATION">
          <div class="modal-title">Recurring Charges Terms and Conditions</div>
          <div class="modal-info-list">
            <div class="modal-info-container">
              <div class="modal-info-title">Payment by Recurring Charges to credit or debit account</div>
              <div class="modal-info-message">
                By signing up for autopay with your credit or debit card, you accept this Agreement and
                authorize Liberty Mutual Insurance Company, for itself and its affiliates, including those
                collectively known as Safeco Insurance, to initiate Recurring Charges to the account
                identified, according to your chosen payment frequency. You also agree that we may
                receive updated information on your account from the card issuer.
              </div>
            </div>
            <div class="modal-info-container">
              <div class="modal-info-title">Renewal of Recurring Charges Agreement</div>
              <div class="modal-info-message">
                Prior to the expiration of this Agreement, we will mail you a renewal declaration,
                which will include your new premium amount and your chosen bill method for the renewal
                insurance contract. Your bill method for the renewal will be recurring credit or debit
                card, unless you change how you pay before the declaration is sent. If you would like
                to change how you pay at any time (before or after the declaration is sent), please
                contact your local Safeco agent.
              </div>
            </div>
            <div class="modal-info-container">
              <div class="modal-info-title">Change of payment method</div>
              <div class="modal-info-message">
                You may cancel Recurring Charges as your payment plan at any time. If you wish to cancel
                this Agreement to pay through Recurring Charges, please edit your payment settings by
                accessing your online account or contact your local Safeco agent. You will need to select
                another payment method unless your policy is fully paid.
              </div>
            </div>
            <div class="modal-info-container">
              <div class="modal-info-title">Recurring Charges refund policy</div>
              <div class="modal-info-message">
                If you are due a premium refund on your policy and you are on recurring credit card or
                debit account billing, we will attempt to refund the credit due to the card or account
                from which the payment was received. If this form of refund is unsuccessful,
                we will typically mail a refund check.
              </div>
            </div>
            <div class="modal-info-container">
              <div class="modal-info-title">Voluntary policy cancellation</div>
              <div class="modal-info-message">
                You may cancel your policy voluntarily at any time. At cancellation, Liberty Mutual will
                assess the premium dollar amount you paid vs. the amount owed for the coverage you received.
                If your policy has been overpaid, we will attempt to refund the credit due to the card or
                account from which the payment was received. If this form of refund is unsuccessful, we will
                typically mail a refund check. If your policy is underpaid, you will be issued a paper bill
                to settle the final amount due.
              </div>
            </div>
            <div class="modal-info-container">
              <div class="modal-info-title">Involuntary policy cancellation</div>
              <div class="modal-info-message">
                If a Recurring Charge attempt is declined and the amount attempted remains unpaid on the next
                occurrence of your billing day, your policy will be moved to direct bill and sent a past-due bill.
                If you do not pay the bill by the due date, your account will be reviewed for nonpayment and pending
                cancellation status. If you are placed in nonpayment, a nonpayment notice will be issued communicating
                a date that your policy will be canceled if the minimum amount is not paid. If this date passes and
                the minimum amount is not paid, your policy will be canceled as of the date communicated in the
                nonpayment notice.
              </div>
            </div>
            <div class="modal-info-container">
              <div class="modal-info-title">Electronic communication policy</div>
              <div class="modal-info-message">
                You agree that we may communicate with you regarding this Agreement and our business relationship
                with you by electronic mail (email) as permitted under applicable laws. To receive emails from us,
                you must have regular access to the following technology: a computer or electronic device such as
                a laptop, tablet, smartphone, PDA, etc., which has access to the internet and can obtain email from
                your email provider. The internet connection must be sufficient to obtain emails so that you can
                read them. A dialup internet connection is the minimum requirement.
              </div>
            </div>
          </div>
        </div>
        <div class="mail-certificate-success-container" *ngIf="modalName === modalNames.MAIL_CERT_SUCCESS">
          <div class="success-icon-container">
            <img src="{{imgLoc}}/icons/success_58x58.svg" class="success-icon" alt="success" />
          </div>
          <div class="success-text">
            Got it! The company on file will receive your certificate <br>of insurance by mail in just a few
            business days.
          </div>
        </div>
        <div class="landing-policy-documents-modal-container modal-container"
          *ngIf="modalName === modalNames.POLICY_DOCUMENTS">
          <div id="statusPageSpinner" class="spinner-card" *ngIf="!isModalLoaded">
            <app-spinner></app-spinner>
          </div>
        </div>
        <!-- Re-enrollment CA Paperless Modal: at least one CA Policy and needs to be re-enrolled -->
        <div *ngIf="caPaperlessLaunched"> <!-- Re-enrollment -->
          <div class="landing-policy-paperless-modal-container modal-container margin-large-tb margin-small-lr"
            *ngIf="modalName === modalNames.PAPERLESS_CA_REENROLL">
            <div class="paperless-container">
              <div class="update-spinner-overlay paperless-spinner-overlay" *ngIf="isPaperlessUpdateInProgress">
                <div class="update-spinner-container">
                  <app-spinner></app-spinner>
                </div>
              </div>
              <div class="paperless-header">
                <app-icon name="paperless" size="48" color="grey"></app-icon>
              </div>
              <h2 class="paperless-modal-title">Ensure you're paperless!</h2>
              <span class="paperless-text-ca">
                We recently updated our paperless terms and conditions. To ensure all your policies that have paperless options 
                available are enrolled in paperless, select I agree below. You can always change your paperless options in your account settings.
              </span>
              <div class="paperless-text-ca paperless-terms-title">{{paperlessTermsTitle}}</div>
              <!-- TBD Update with Legal text for re-enroll - paperless scrollable text box with T&Cs-->
              <div enableButtonScroll class="paperless-scroll-terms paperless-terms-scrollbar"
                #paperlessScrollElement="enableButtonScroll" #scroll tabindex="0" role="region"
                aria-label="scrollable content: Safeco Paperless Terms and Conditions">
                <paperless-terms-content [customerName]="customerName" [emailAddress]="emailAddress"
                  [paperlessTermsAndConditions]="paperlessTermsAndConditions" />
              </div>
              <div *ngIf="showModalErrorActionBadge" class="select-option-badge margin-top">
                <app-badge [badgeText]="modalActionErrorBadgeText" [highlightType]="color.NEGATIVE"></app-badge>
              </div>
              <div class="paperless-tc-agree-text">
                By selecting <span class="paperless-tc-agree">I agree</span>, I am electronically signing the Safeco
                Paperless Terms and Conditions and consent to receive documents electronically.
              </div>
              <div class="paperless-modal-button-group">
                <div *ngIf="!mobileQuery.matches">
                  <button class="sco-button sm_cobrowsing_disabled_field sco--fill paperless-submit-button-ca-paperless"
                    (click)="reenrollPaperless()" data-heap-id="LP_popUp_btn_paperlessReenroll" name="Submit changes"
                    name="Yes, enroll in paperless" role="button" [disabled]="paperlessScrollElement.disableBtn">
                    I agree
                  </button>
                </div>
                <div *ngIf="mobileQuery.matches">
                  <button class="sco-button sm_cobrowsing_disabled_field sco--fill paperless-submit-button-ca-paperless"
                    (click)="reenrollPaperless()" data-heap-id="LP_popUp_btn_paperlessReenroll" name="Submit changes"
                    name="Yes, enroll in paperless" role="button">
                    I agree
                  </button>
                </div>
                <div>
                  <span class="paperless-text-ca">No thanks, please
                    <a class="sco-link sm_cobrowsing_disabled_field sco--standalone paperless-no-thanks-link"
                      [routerLink]="[]" (click)="mailDocuments()" data-heap-id="LP_popUp_txt_NoThanksMailMyDocuments"
                      name="No thanks, please mail my documents" aria-label="No thanks, please mail my documents"
                      role="button">
                      mail my documents</a>.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end of Re-enrollment CA Paperless Modal -->
        <!-- CA Paperless Modal at least one CA Policy -->
        <div class="landing-policy-paperless-modal-container modal-container margin-large-tb margin-small-lr"
          *ngIf="modalName === modalNames.PAPERLESS_CA">
          <div class="paperless-container">
            <div class="update-spinner-overlay paperless-spinner-overlay" *ngIf="isPaperlessUpdateInProgress">
              <div class="update-spinner-container">
                <app-spinner></app-spinner>
              </div>
            </div>
            <div class="paperless-header">
              <app-icon name="paperless" size="48" color="grey"></app-icon>
            </div>
            <h2 class="paperless-modal-title">{{paperlessModalTitleText}}</h2>
            <span class="paperless-text-ca">{{paperlessModalParagraphText}}</span>
            <div class="paperless-text-ca paperless-terms-title">{{paperlessTermsTitle}}</div>
            <!-- paperless scrollable text box with T&Cs-->
            <div enableButtonScroll class="paperless-scroll-terms paperless-terms-scrollbar"
              #paperlessScrollElement="enableButtonScroll" #scroll tabindex="0" role="region"
              aria-label="scrollable content: Safeco Paperless Terms and Conditions">
              <paperless-terms-content [customerName]="customerName" [emailAddress]="emailAddress"
                [paperlessTermsAndConditions]="paperlessTermsAndConditions" />
            </div>
            <div *ngIf="showModalErrorActionBadge" class="select-option-badge margin-top">
              <app-badge [badgeText]="modalActionErrorBadgeText" [highlightType]="color.NEGATIVE"></app-badge>
            </div>
            <div class="paperless-tc-agree-text">
              By selecting <span class="paperless-tc-agree">I agree</span>, I am electronically signing the Safeco
              Paperless Terms and Conditions and consent to receive documents electronically.
            </div>
            <div class="paperless-modal-button-group">
              <div *ngIf="!mobileQuery.matches">
                <button class="sco-button sm_cobrowsing_disabled_field sco--fill paperless-submit-button-ca-paperless"
                  (click)="goPaperlessLink('Y')" data-heap-id="LP_popUp_btn_goPaperless" name="Submit changes"
                  name="Yes, enroll in paperless" role="button" [disabled]="paperlessScrollElement.disableBtn">
                  I agree
                </button>
              </div>
              <div *ngIf="mobileQuery.matches">
                <button class="sco-button sm_cobrowsing_disabled_field sco--fill paperless-submit-button-ca-paperless"
                  (click)="goPaperlessLink('Y')" data-heap-id="LP_popUp_btn_goPaperless" name="Submit changes"
                  name="Yes, enroll in paperless" role="button">
                  I agree
                </button>
              </div>
              <div>
                <span class="paperless-text-ca">No thanks, please
                  <a class="sco-link sm_cobrowsing_disabled_field sco--standalone paperless-no-thanks-link"
                    [routerLink]="[]" (click)="goPaperlessLink('N')" data-heap-id="LP_popUp_txt_NoThanksIPreferPaper"
                    name="No thanks, please mail my documents" aria-label="No thanks, please mail my documents"
                    role="button">
                    mail my documents</a>.
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- end of CA Paperless Modal -->
        <!-- Non-CA Paperless Modal -->
        <div class="landing-policy-paperless-modal-container modal-container margin-large-tb margin-small-lr"
          *ngIf="modalName === modalNames.PAPERLESS">
          <div class="paperless-container">
            <div class="update-spinner-overlay paperless-spinner-overlay" *ngIf="isPaperlessUpdateInProgress">
              <div class="update-spinner-container">
                <app-spinner></app-spinner>
              </div>
            </div>
            <div class="paperless-header">
              <app-icon name="paperless" size="48" color="grey"></app-icon>
            </div>
            <h2 class="paperless-modal-title">{{paperlessModalTitleText}}</h2>
            <span class="paperless-text-ca">{{paperlessModalParagraphText}}</span>
            <div class="paperless-text-ca paperless-terms-title">{{paperlessTermsTitle}}</div>
            <!-- paperless scrollable text box with T&Cs-->
            <div enableButtonScroll class="paperless-scroll-terms paperless-terms-scrollbar"
              #paperlessScrollElement="enableButtonScroll" #scroll tabindex="0" role="region"
              aria-label="scrollable content: Safeco Paperless Terms and Conditions">
              <paperless-terms-content [customerName]="customerName" [emailAddress]="emailAddress"
                [paperlessTermsAndConditions]="paperlessTermsAndConditions" />
            </div>
            <div *ngIf="showModalErrorActionBadge" class="select-option-badge margin-top">
              <app-badge [badgeText]="modalActionErrorBadgeText" [highlightType]="color.NEGATIVE"></app-badge>
            </div>
            <div class="paperless-modal-button-group">
              <div *ngIf="!mobileQuery.matches">
                <button class="sco-button sm_cobrowsing_disabled_field sco--fill paperless-submit-button-ca-paperless"
                  (click)="goPaperlessLink('Y')" data-heap-id="LP_popUp_btn_goPaperless" name="Submit changes"
                  name="Yes, enroll in paperless" role="button" [disabled]="paperlessScrollElement.disableBtn">
                  Sounds great!
                </button>
              </div>
              <div *ngIf="mobileQuery.matches">
                <button class="sco-button sm_cobrowsing_disabled_field sco--fill paperless-submit-button-ca-paperless"
                  (click)="goPaperlessLink('Y')" data-heap-id="LP_popUp_btn_goPaperless" name="Submit changes"
                  name="Yes, enroll in paperless" role="button">
                  Sounds great!
                </button>
              </div>
              <div>
                <span class="paperless-text-ca">No thanks, please
                  <a class="sco-link sm_cobrowsing_disabled_field sco--standalone paperless-no-thanks-link"
                    [routerLink]="[]" (click)="goPaperlessLink('N')" data-heap-id="LP_popUp_txt_NoThanksIPreferPaper"
                    name="No thanks, please mail my documents" aria-label="No thanks, please mail my documents"
                    role="button">
                    mail my documents</a>.
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- end of Non-CA Paperless Modal -->
        @if (modalName === modalNames.UPDATE_AUTOPAY_SUCCESS)
        {
        <div class="update-autopay-success-container">
          <div class="success-icon-container">
            <app-icon name="successAlert" color='success' size="48"></app-icon>
          </div>
          <div class="success-title-container margin-bottom-large">
            <h1 class="success-title sco-heading sco--level3 sco--light">
              {{ autopaySuccessMessage }}
            </h1>
          </div>
          @if (bookTransferInfo)
          {
          <div class="book-transfer">
            <div>
              <b>{{ bookTransferInfo.billingMessageTitle }}</b>
            </div>
            <div>{{ bookTransferInfo.billingMessageContent }}
              <a class="update-link sco-link sco--standalone" href="{{bookTransferInfo.carrierLoginUrl}}"
                (click)="closeModal()" [attr.data-heap-id]="bookTransferInfo.carrierLoginHeapId + '_AutoPay'"
                name="{{bookTransferInfo.carrierLoginText}}" [attr.aria-label]="bookTransferInfo.carrierLoginText"
                role="link" target="_blank">
                {{bookTransferInfo.carrierLoginText}}
              </a>
            </div>
          </div>
          }
          <lmds-button id="modal-update-autopay-success-close" class="lmds-button-dynamic-width" 
            variant="secondary" (buttonClick)="closeModal()">Close</lmds-button>
        </div>
        }

        @if (modalName === modalNames.PAYMENT_SETTINGS_SUCCESS)
        {
        <div class="payment-settings-success-container">
          <div class="success-icon-container">
            <app-icon name="successAlert" color='success' size="48"></app-icon>
          </div>
          <div class="success-title-container">
            <h1 class="success-title sco-heading sco--level3 sco--light">Got it!</h1>
          </div>
          <div class="messages">
            <p class="message" [innerHTML]="paymentSettingsSuccessMessages[0]"></p>
            <p class="message" [innerHTML]="paymentSettingsSuccessMessages[1]"></p>
          </div>
          @if (bookTransferInfo) {
          <div class="book-transfer">
            <div>
              <b>{{ bookTransferInfo.billingMessageTitle }}</b>
            </div>
            <div>{{ bookTransferInfo.billingMessageContent }}
              <a class="update-link sco-link sco--standalone" href="{{bookTransferInfo.carrierLoginUrl}}"
                (click)="closeModal()" [attr.data-heap-id]="bookTransferInfo.carrierLoginHeapId + '_PaymentSettings'"
                name="{{bookTransferInfo.carrierLoginText}}" [attr.aria-label]="bookTransferInfo.carrierLoginText"
                role="link" target="_blank">
                {{bookTransferInfo.carrierLoginText}}
              </a>
            </div>
          </div>
          }
          <lmds-button id="modal-update-autopay-success-close" class="lmds-button-dynamic-width" 
            variant="secondary" (buttonClick)="closeModal()">Close</lmds-button>
        </div>
        }

        @if (modalName === modalNames.POSTPONE_PAYMENT_SUCCESS)
        {
        <div class="postpone-payment-success-container">
          <div class="success-icon-container">
            <app-icon name="successAlert" color='success' size="48"></app-icon>
          </div>
          <div class="success-title-container">
            <h1 class="success-title sco-heading sco--level3 sco--light">You {{
              postponePaymentSuccessInfo.movedOrDelayed }} your {{postponePaymentSuccessInfo.formerDate
              | date: 'MMMM d'}} {{postponePaymentSuccessInfo.paymentType}} to
              {{postponePaymentSuccessInfo.postponeDate | date: 'MMMM d'}}</h1>

          </div>
          <div class="messages">
            <p>
              Any other upcoming {{postponePaymentSuccessInfo.paymentType}}s will still occur on the
              {{postponePaymentSuccessInfo.monthlyDueDate}}.
            </p>
          </div>
          @if (bookTransferInfo) {
          <div class="book-transfer">
            <div>
              <b>{{ bookTransferInfo.billingMessageTitle }}</b>
            </div>
            <div>{{ bookTransferInfo.billingMessageContent }}
              <a class="update-link sco-link sco--standalone" href="{{bookTransferInfo.carrierLoginUrl}}"
                (click)="closeModal()" [attr.data-heap-id]="bookTransferInfo.carrierLoginHeapId + '_PostponePayment'"
                name="{{bookTransferInfo.carrierLoginText}}" [attr.aria-label]="bookTransferInfo.carrierLoginText"
                role="link" target="_blank">
                {{bookTransferInfo.carrierLoginText}}
              </a>
            </div>
          </div>
          }
          <lmds-button id="modal-update-autopay-success-close" class="lmds-button-dynamic-width" 
            variant="secondary" (buttonClick)="closeModal()">Close</lmds-button>
        </div>
        }
        <div class="modal-container cross-sell-modal-container" *ngIf="modalName === modalNames.CROSS_SELL">
          <div>
            <span class="cross-sell-icon-sml">
              <app-icon name="{{offerLob}}" size="32" color="dark" circleColor="gray"></app-icon>
            </span>
            <span class="sco--customer sco-heading sco--level3 sco--regular sco--noMarginTop">{{ xSellHeader
              }}</span>
          </div>
          <div class="cross-sell-med-bold">{{ xSellBody }}</div>
          <!--<div class="cross-sell-med-bold">You already have Safeco {{ sourcePolicyLob }} coverage. Adding {{ offerLob }} could save you ${{ offerSavings }}.</div>-->
          <div *ngIf="(!isGoldAgency && !isGoldPlusAgency)" class="cross-sell-main">
            <div class="cross-sell-med">Your local Safeco agent will be in touch soon with more details.
            </div>
            <div class="cross-sell-sml">If you don’t hear from your agent within the next few days, be sure
              to reach out.</div>
            <p class="cross-sell-sml">{{ agencyName }}<br /> {{ agencyPhone }}
            </p>
          </div>

          <div *ngIf="(isGoldAgency || isGoldPlusAgency)" class="cross-sell-gold">
            Call us to learn more<br />
            <a href="tel:+{{ goldPhoneNumber | phoneNumber }}">
              <button class="sco--customer sco-button sco--fill" style="margin-top: 16px;"
                name="Call phone number {{goldPhoneNumber}}" role="button" tabindex="0"
                data-heap-id="LP_popUp-btn-xSell-callGold">
                {{ goldPhoneNumber | phoneNumber }}
              </button>
            </a>
          </div>
          <div>
            <hr width="100%">
            <span name="Savings terms and conditions" aria-label="Savings terms and conditions" class="cross-sell-xs">
              {{ xSellDisclaimer }}
            </span>
          </div>
        </div>
        <div id="policyNumImageModal" class="modal-container policy-number-img-container"
          *ngIf="modalName === modalNames.POLICY_NUM_IMGS">
          <span class="title">Where's my policy number?</span>
          <div>
            <span>Bill</span>
            <img id="u1468_img" src="{{imgLoc}}/find-policy-number/YourBill_Minimized.png" title="Safeco bill"
              alt="Safeco bill. Find policy number in Billing Detail / Description section." tabindex="0">
          </div>
          <div>
            <span>ID card (auto insurance only)</span>
            <img id="u1468_img" src="{{imgLoc}}/find-policy-number/YourIdCard_Minimized.png" title="Safeco auto ID card"
              alt="Safeco auto ID card. Find policy number under Policy Number heading, near upper left corner."
              tabindex="0">
          </div>
          <div>
            <span>Policy documents</span>
            <img id="u1468_img" src="{{imgLoc}}/find-policy-number/YourPolicyDeclarations_Minimized.png"
              title="Safeco policy declaration"
              alt="Safeco policy declaration. Find policy number next to Policy Number heading in upper right corner."
              tabindex="0">
          </div>
          <button class="sco-button sco--outline" style="margin-top: 32px;" (click)="closeModal()" name="Close modal">
            Close
          </button>
        </div>
        <div class="modal-container add-driver-businessUse-info"
          *ngIf="modalName === modalNames.CHANGE_DRIVER_BUSINESS_USE">
          <div class="info-text">
            Work/school commuting doesn't qualify as business use
          </div>
        </div>
        <div class="modal-container add-driver-incidentDescription-info"
          *ngIf="modalName === modalNames.ADD_DRIVER_INCIDENT">
          <div class="info-text">
            Provide the date; location; type of violation (such as speeding) or accident (such as at fault
            or not at fault); amount of damage (if any); and circumstances of the incident.
          </div>
        </div>
        <div class="modal-container add-vehicle-info" *ngIf="modalName === modalNames.ADD_VEHICLE_INFO">
          <div class="new-vehicle-title">New wheels?</div>
          <div>
            <ul>
              <li>
                <div class="list-description">
                  Tell us about your new vehicle now.
                </div>
              </li>
              <li>
                <div class="list-description">
                  See your updated policy and premium in 1-3 business days.
                </div>
              </li>
            </ul>

            <div class="navigation-buttons">
              <button class="sco-button sco--fill continue-button" role="button" (click)="contactAgent(false)"
                name="Navigate to next view" id="AddVehicle_firstPage">
                Get
                started
              </button>
            </div>
          </div>
        </div>
        <div class="modal-container add-vehicle-info" *ngIf="modalName === modalNames.REPLACE_VEHICLE_INFO">
          <div class="new-vehicle-title">Replace a vehicle</div>
          <div>
            <ul>
              <li>
                <div class="list-description">
                  We just need some basic details about your new vehicle
                </div>
              </li>
              <li>
                <div class="list-description">
                  Your updated policy and premium will be ready in 1-3 business days in your online
                  account or the mobile app
                </div>
              </li>
            </ul>
            <div class="navigation-buttons">
              <button class="sco-button sco--fill continue-button" role="button" (click)="contactAgent(false)"
                name="Navigate to next view" id="AddVehicle_firstPage">
                Get
                started
              </button>
            </div>
          </div>
        </div>

        <div class="modal-container sms-opt-out-success-container" *ngIf="modalName === modalNames.OPT_OUT_SMS_SUCCESS">
          <div class="success-icon-container">
            <app-icon name="successAlert" color='success' size="64"></app-icon>
          </div>
          <div class="modal-title">You will no longer <br> receive text alerts.<br><br></div>
          <div class="agent-button">
            <button class="sco-button sco--fill" role="button" (click)="closeModal()" name="close-button"
              id="Sms_Opt_Out">Ok thanks</button>
          </div>
        </div>
        <!-- <div class="back-button-container" *ngIf="shouldButtonShow()">
            <button class="sco-button sco--fill" (click)="closeModal()" name="Close modal">Close</button>
        </div> -->
        <div class="modal-container exit-survey" *ngIf="modalName === modalNames.EXIT_SURVEY">
          <div *ngIf="!isRequestInProgress">
            <div class="sco-heading sco--level2 sco--regular">Are you sure you want to go?</div>
            <div class="modal-sub-heading"><b>Any information you entered won't be saved.</b></div>
            <div class="modal-sub-heading">Before you do, could you tell us how we could improve this
              experience?</div>
          </div>
          <div class="survey-textarea-container">
            <div *ngIf="!isRequestInProgress">
              <app-textarea-input id="exit_survey_modal" placeholder="Type your answer here"
                [(value)]="exitSurveyTextArea" hideHelperText="true" [(ngModel)]="exitSurveyTextArea" ngDefaultControl>
              </app-textarea-input>
            </div>
            <div class="progress-spinner" *ngIf="isRequestInProgress">
              <app-spinner></app-spinner>
            </div>
          </div>
          <div class="sco-button-group">
            <button class="sco-button sco--outline" name="Cancel button" [id]="sourceComponent + '_skipSurveyButton'"
              role="button" (click)="setSurveyResponse(false)">
              Skip and exit
            </button>
            <button class="sco-button sco--fill no-margin-right" name="Submit button"
              [id]="sourceComponent + '_submitSurveyButton'" role="button" [disabled]="disableSurveySubmitButton()"
              (click)="setSurveyResponse(true)">
              Send feedback and exit
            </button>
          </div>
        </div>

        <div class="modal-container margin-large-tb margin-small-lr sms-cta-container"
          *ngIf="modalName === modalNames.SMS_SIGNUP">
          <div class="sms-header">
            <app-icon name="textAlerts" size="48" color="grey"></app-icon>
            <div class="modal-title">
              <span>Turn on text alerts, and make sure you never miss a payment!</span>
            </div>
          </div>
          <div class="sms-text">
            Text alerts can always be turned on or off in your online account.
          </div>
          <button class="sco-button sco--fill sms-cta-button" name="Turn on text alerts" [id]="modal-sms-enroll"
            data-heap-id="LP_popUp_btn_SmsTurnOn" role="button" (click)="navigateToTextAlerts()">
            Turn on text alerts
          </button>
          <div>
            <a class="sco-link sm_cobrowsing_disabled_field sco--standalone sms-no-thanks-link" [routerLink]="[]"
              (click)="closeModal()" data-heap-id="LP_popUp_txt_SmsNoThanks"
              name="No thanks, I do not want to turn on SMS text alerts"
              aria-label="No thanks, I do not want to turn on SMS text alerts" role="link">
              No thanks, not at this time
            </a>
          </div>
        </div>

        <div class="modal-container margin-small-lr" *ngIf="modalName === modalNames.CANCEL_SCHEDULE_PAYMENT">
          <h3 lmdsHeading type="h3-light">Are you sure you want to cancel your scheduled payment?</h3>
          <div class="progress-spinner" *ngIf="isRequestInProgress">
            <app-spinner></app-spinner>
          </div>
          <div *ngIf="!isRequestInProgress" lmdsGridRow gutterSize="fixed-16" [gutters]="true">
            <div lmdsGridCol [base]="12" [md]="6">
              <lmds-button class="lmds-button-dynamic-width" id="modal-SAP-dontCancel" variant="secondary"
                [dynamicWidth]="true" (buttonClick)="closeModal('popUp_btn_dontCancelSchedulePayment')">
                No, don't cancel
              </lmds-button>
            </div>
            <div lmdsGridCol [base]="12" [md]="6">
              <lmds-button class="lmds-button-dynamic-width" id="modal-SAP-cancel" variant="primary"
                [dynamicWidth]="true" (buttonClick)="cancelSchedulePayment($event)">
                Yes, cancel my payment
              </lmds-button>
            </div>
          </div>
        </div>

        @if (modalName === modalNames.REVIEW_AUTOPAY_UNENROLL)
        {
        <div id="review-autopay-unenroll-container" class="modal-container">
          <div class="content-scroll" id="unenroll-autopay-content">
            <h3 lmdsHeading type="h3-light">Heads up! Turning off Autopay will affect your payments.</h3>
            <p>If you turn off Autopay, your yearly premium will stay the same, but
              <b>your payment amounts might change.</b>
            </p>

            <p>Here’s a comparison of how your payments may be affected.</p>

            <div lmdsGridRow id="autopay-unenroll-preview">
              <div lmdsGridRow id="review-header">
                <div lmdsGridCol class="header-label" [base]="5">Due date</div>
                <div lmdsGridCol class="header-label" [base]="3" [sm]="4">Autopay</div>
                <div lmdsGridCol class="header-label manual" [base]="4" [sm]="3">Manual</div>
              </div>
              @for (reviewItem of reviewAutopayUnenrollSchedule; track reviewItem; let i = $index)
              {
              <div lmdsGridRow class="review-row">
                <div lmdsGridCol class="review-col due-date" [base]="5">{{ reviewItem.autopayBillDueDate | date: 'mediumDate' }}
                </div>
                <div lmdsGridCol class="review-col autopay" [base]="3" [sm]="4">{{ reviewItem.autopayBillMinDueAmount | currency
                  }}</div>
                <div lmdsGridCol class="review-col manual" [base]="4" [sm]="3">

                  <!-- need to modify this if condition based on the data when it's actually connected to the service call -->
                  @if (reviewItem.showAdditionalChargesPopover) {
                  <div id="unenroll-popover">
                    <lmds-popover>
                      Manual payments must be paid 30 days in advance. For that reason, you will be charged for the
                      next two payments together in your first manual payment (plus any fees). You won't be charged
                      for the last payment of your term, so your total premium will stay the same.
                    </lmds-popover>
                  </div>
                  }
                  {{ reviewItem.manualBillMinDueAmount | currency }}
                </div>
              </div>
              }
            </div>
            <p id="processing-fees-msg">*Price includes processing fees.</p>
          </div>
          <br />
          <div lmdsGridRow id="autopay-unenroll-buttons" [gutters]="true" [gutterSize]="'fixed-16'">
            <div lmdsGridCol id="autopay-unenroll-button" [base]="12" [md]="6">
              <lmds-button class="lmds-button-dynamic-width" id="modal-turn-off-autopay" variant="primary"
                [dynamicWidth]="true" (buttonClick)="confirmAutopayUnenrollment()">
                OK, turn off Autopay
              </lmds-button>
            </div>
            <div lmdsGridCol id="autopay-unenroll-cancel-button" [base]="12" [md]="6">
              <lmds-button class="lmds-button-dynamic-width" id="modal-turn-off-autopay-cancel" variant="secondary"
                [dynamicWidth]="true" (buttonClick)="closeModal('Autopay_popUp_btn_cancelUnenroll')">
                Cancel
              </lmds-button>
            </div>
          </div>
        </div>
        }
      </div>

      <!-- this is flex-direction column-reversed to put at bottom for tab index, but still visually is top -->
      <div class="top-close-button-container" *ngIf="showCloseButton" id="top-close-button-container">
        <button class="close-icon" (click)="closeModal()" aria-label="Close">
          <app-icon name="close"></app-icon>
        </button>
      </div>

      <div tabindex="0" id="modal_hidden_end" role="contentinfo" aria-label="End of Modal"
        style="opacity: 0; height: 0; position: fixed; top: -10px" (focus)="setModalFocus()"></div>
    </div>
  </div>
</div>