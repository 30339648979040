import { AutoPayError, AutoPayRq } from "../models/autoPay";
import Billing from "../models/billing";
import PaymentsMethods from "./payments.methods";

export default class AutoPayMethods {
    public static buildAutoPayEnrollRequest(instrumentIdCode: string, billingAccount: Billing): AutoPayRq {
        const autoPayRq = new AutoPayRq();
    
        autoPayRq.instrumentIdRq = PaymentsMethods.buildInstrumentIdRq(instrumentIdCode);
        autoPayRq.policyNumber = billingAccount.policyNumber;
        return autoPayRq;
    }

    public static isInstrumentDetailsError(instrumentPayError: AutoPayError): boolean {
        let isInstrumentError = false;
    
      if (instrumentPayError && instrumentPayError.instrumentErrorCode) {
          isInstrumentError = true;
        }
    
        return isInstrumentError;
    }

    
}
