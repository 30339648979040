import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import CAMStorage from 'src/app/utilities/constants/CAMStorage';

import { Alert } from 'src/app/utilities/models/alert';
import {
  CreditCardInstrumentDetailsRs,
  DeleteCreditCardProfileRq,
  InitiateInstrument,
  InstrumentDetailsEvent,
  InstrumentDetailsStorage,
  SaveCreditCardProfileRq,
  SavedInstrument
} from 'src/app/utilities/models/payments';

import { PaymentsService } from 'src/app/utilities/services/payments-service/payments.service';

import PaymentsMethods from 'src/app/utilities/methods/payments.methods';
import { Features } from 'src/app/utilities/models/features';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { PAYMENT_INFO_STATUS_CODES } from 'src/app/utilities/constants/payment';
import { ModalService } from 'src/app/utilities/services/modal-service/modal.service';
import { ModalNames } from 'src/app/utilities/constants/modalNames';
import { AutoPayRs } from 'src/app/utilities/models/autoPay';
import { AutoPayService } from 'src/app/utilities/services/autopay-service/autopay.service';
import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import AutoPayMethods from 'src/app/utilities/methods/autopay.methods';
import { AccountOptionsPaymentMethod } from 'src/app/utilities/models/billingAccountOptions';
import { InstrumentType } from 'src/app/utilities/constants/paymentInstrument';
import Billing from 'src/app/utilities/models/billing';

@Component({
  selector: 'app-credit-card-input',
  templateUrl: './credit-card-input.component.html',
  styleUrls: ['./credit-card-input.component.scss']
})
export class CreditCardInputNewComponent implements OnInit {

  constructor(
    private paymentsService: PaymentsService,
    private autoPayService: AutoPayService,
    private commonService: CommonService,
    private modalService: ModalService,
    private analyticsService: AnalyticsService
  ) {
  }

  @Input() isGuestPayment?: boolean = false;
  @Input() isRCC?: boolean = false;
  @Input() isRCCPaymentMethod?: boolean = false;
  @Input() isRegularPaymentMethod?: boolean = false;
  @Input() accountOptions?: AccountOptionsPaymentMethod = null;
  @Input() selectedBillingAccount?: Billing = null;
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() authorizeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendAlert: EventEmitter<any> = new EventEmitter<any>();

  alerts: Alert[] = [];
  backButtonText: string;
  backButtonId: string;
  cardAction: string;
  cardActions = {
    EDIT: 'edit',
    DELETE: 'delete'
  };
  cardActionSuccessMessage: string;
  cardDeletionHasError: boolean = false;
  cardUpdateHasError: boolean;
  cardOptions: CreditCardInstrumentDetailsRs[] = [];
  cardSelectionHasError: boolean = true;
  disclaimerAgreementText: string = 'By continuing with this payment method, you agree that you are the owner of the payment method and are authorizing payment from this payment method.';
  displayConfirmDelete: boolean = false;
  displayEditIFrameApp: boolean = false;
  features: Features = new Features();
  instrumentDetailsStorage: InstrumentDetailsStorage;
  instrumentIdCode: string;
  isCCProfileEnabled: boolean = false;
  isCCZipCodeEnabled: boolean = false;
  isCardDeclined: boolean = false;
  isCardUpdated: boolean = false;
  isEditCardSelected: boolean = false;
  isNewCardSelected: boolean = false;
  isPaymentInfoInError: boolean = false;
  iFrameUrl: string;
  nextButtonText: string;
  saveCard: boolean = false;
  savedCardDetails: CreditCardInstrumentDetailsRs;
  savedInstrumentIdCode: string;
  savedInstrumentIdCodeHasError: boolean;
  savedInstrumentPaymentSource: string;
  selectedCard: CreditCardInstrumentDetailsRs;
  showCAMControls: boolean = false;
  showCardActionConfirmation: boolean = false;
  showCardActionSuccess: boolean = false;
  showCardOptions: boolean = false;
  showIFrameApp: boolean = false;
  showSpinner: boolean = true;
  showSubmitDisclaimer: boolean = false;

  ngOnInit() {
    this.initializeCreditCardInput();
  }

  async ngDoCheck() {
    if (CAMStorage.getItemAsBool(CAMStorage.storageKeys.getInstrumentDetails)) {
      CAMStorage.removeItemInStorage(CAMStorage.storageKeys.getInstrumentDetails);

      if (this.isRCC) {
        // Hydra RCC Aggregator call
        this.analyticsService.trackAnalytics("MMA_btn_HydraRCC_Submit");
        this.enrollCreditCardToAutoPay();
      } else {
        // getInstrumentDetails for MAP
        this.analyticsService.trackAnalytics("MMA_btn_MAP_PaymentMethodSubmit");
        this.verifyInstrument();
      }
    }
  }

  async initializeCreditCardInput(): Promise<void> {
    this.getPaymentStorage();
    await this.getFeatures();

    if (this.instrumentDetailsStorage) {
      if (this.isCCProfileEnabled && this.savedInstrumentIdCode && !this.savedInstrumentIdCodeHasError && !this.savedCardDetails) {
        this.savedCardDetails = await this.getInstrumentDetails(this.savedInstrumentIdCode, true, this.savedInstrumentPaymentSource);
      }

      await this.configureCreditCardDisplay();
    }
  }

  async getFeatures(): Promise<void> {
    try {
      this.features = await this.commonService.getFeatures();
      if (this.features) {
        this.isCCProfileEnabled = (this.features.creditCardProfilesLaunched && !this.isGuestPayment && !this.isRCC);
        this.isCCZipCodeEnabled = this.features.creditCardZipCodeLaunched;
      }
    } catch (error) {
      console.log(error);
    }
  }

  getPaymentStorage(): void {
    this.instrumentDetailsStorage = CAMStorage.getItemInStorage(CAMStorage.storageKeys.instrumentDetailsStorage, true);
    this.setSavedInstrumentData();
  }

  setSavedInstrumentData() : void {
    const savedInstrument: SavedInstrument = CAMStorage.getItemInStorage(CAMStorage.storageKeys.savedInstrument, true);

    if (savedInstrument) {
      this.savedInstrumentIdCode = savedInstrument.code;
      this.savedInstrumentPaymentSource = savedInstrument.paymentSource;
      this.savedInstrumentIdCodeHasError = CAMStorage.getItemAsBool(CAMStorage.storageKeys.savedInstrumentIdCodeHasError);
    }
  }

  buildCardOptions(): void {
    this.cardOptions = [];

    if (this.savedCardDetails) {
      this.cardOptions.push(this.savedCardDetails);
    }

    const cardInstrumentDetailsResponse = this.instrumentDetailsStorage.cardInstrumentDetailsResponse;
    if (cardInstrumentDetailsResponse && cardInstrumentDetailsResponse.instrument) {
      if (!this.isSavedCardSelected(this.instrumentDetailsStorage.cardInstrumentDetailsResponse)) {
        this.cardOptions.push(this.instrumentDetailsStorage.cardInstrumentDetailsResponse);
      }
    }
  }

  async configureCreditCardDisplay(): Promise<void> {
    this.setButtonAttributes();
    this.buildCardOptions();
    this.checkPaymentInfoIsError();

    this.showCardOptions = (this.cardOptions && this.cardOptions.length) ? true : false;

    if (this.isNewCardSelected || !this.showCardOptions || this.isCardDeclined || this.cardUpdateHasError) {
      this.checkForSelectedOption();

      await this.buildHydraCreditCardIFrame();

      if (this.iFrameUrl) {
        if (!this.showCardActionSuccess && this.cardAction === this.cardActions.DELETE) {
          this.setCardActionSuccessDisplay();
        }
      }
    } else if (this.showCardOptions) {
      this.isNewCardSelected = false;
      this.preselectCard();
    }

    this.showSpinner = false;
  }

  checkPaymentInfoIsError(): void {
    const paymentInfoStatus = CAMStorage.getItemInStorage(CAMStorage.storageKeys.getPaymentInfoStatus, true);

    if (paymentInfoStatus && paymentInfoStatus.code) {
      this.isPaymentInfoInError = (paymentInfoStatus.code === PAYMENT_INFO_STATUS_CODES.billInfoForCCProfileFailed) ? true : false;
    }
  }

  async buildHydraCreditCardIFrame(): Promise<void> {
    this.iFrameUrl = '';
    this.showCAMControls = false;
    this.showIFrameApp = true;

    try {
      const billingAccountNumber = this.instrumentDetailsStorage ? this.instrumentDetailsStorage.billingAccountNumber : "";
      let response = await this.paymentsService.initiatePaymentInstrument(InstrumentType.CARD, billingAccountNumber, !this.isGuestPayment);

      if (response) {
        this.setIFrameAppValues(response);
      }
    } catch (error) {
      console.log(error);
      this.setHydraCreditCardIFrameError();
    }
  }

  private setIFrameAppValues(response: InitiateInstrument): void {
    if (response && response.instrumentHostedPageUrl) {
      this.iFrameUrl = response.instrumentHostedPageUrl;
      this.instrumentIdCode = response.instrumentIdCode;
      this.saveCard = false;
      this.showSubmitDisclaimer = this.isRCC ? false : true;

      setTimeout(() => {
        this.showCAMControls = true;
      }, 1000);
    }
  }

  setHydraCreditCardIFrameError() {
    this.showIFrameApp = false;
    this.sendAlert.emit(PaymentsMethods.makeErrorAlertMessage());

    if (this.showCardOptions) {  // a card is already selected
      if (this.isEditCardSelected) {
        this.closeEditSavedCard();
      } else {
        this.preselectCard();
      }

      this.isNewCardSelected = false;
      this.showCAMControls = true;
    } else {
      this.cancelEvent.emit(true);
    }
  }

  checkForSelectedOption(): void {
    if (this.isCardDeclined || this.cardUpdateHasError) {
      if (this.showCardOptions && !this.isNewCardSelected) {
        this.preselectCard();
      } else {
        this.isNewCardSelected = true;
      }
    }

    if (!this.selectedCard || !this.isNewCardSelected) {
      this.isNewCardSelected = true;
    }
  }

  preselectCard(): void {
    if (this.instrumentDetailsStorage.cardInstrumentDetailsResponse) {
      this.selectCardInStorage();
    } else if (this.savedCardDetails) {
      this.selectSavedCard();
    }

    this.showSubmitDisclaimer = false;
    this.showCAMControls = true;
  }


  selectCardInStorage(): void {
    this.selectedCard = this.instrumentDetailsStorage.cardInstrumentDetailsResponse;
    this.isNewCardSelected = false;
    this.cardSelectionHasError = false;

    if (this.isSavedCardSelected(this.instrumentDetailsStorage.cardInstrumentDetailsResponse)) {
      this.saveCard = this.instrumentDetailsStorage.isNewSavedCard ? true : false;
    } else {
      this.saveCard = this.instrumentDetailsStorage.storeInstrumentForReuse;
    }
  }

  selectSavedCard(): void {
    this.selectedCard = this.savedCardDetails;
    this.instrumentDetailsStorage.cardInstrumentDetailsResponse = this.selectedCard;
    this.saveCard = this.instrumentDetailsStorage.isNewSavedCard ? true : false;
    this.isNewCardSelected = false;
  }

  showCardSelect(cardDetails?: CreditCardInstrumentDetailsRs): boolean {
    let showCardSelect: boolean = true;

    const isEdit = (this.cardAction === this.cardActions.EDIT);
    if (!this.showCardActionSuccess && isEdit && !this.isSavedCardSelected(cardDetails)) {
      showCardSelect = false;
    }

    return showCardSelect;
  }

  getCardSelectionLabel(cardDetails: CreditCardInstrumentDetailsRs): string {
    let label = "";

    if (this.isSavedCardSelected(cardDetails)) {
      label = `Saved card: `;
    } else {
      label = `Previously entered card (for a one-time payment only): `;
    }

    return label;
  }

  getCardDisplay(cardDetails: CreditCardInstrumentDetailsRs): string {
    const cardDisplay: string = PaymentsMethods.getPaymentCardDisplay(cardDetails, true, false);

    return cardDisplay;
  }

  selectCard(cardDetails: CreditCardInstrumentDetailsRs): void {
    if (this.getInstrumentIdCode(cardDetails) !== this.getInstrumentIdCode(this.selectedCard)) {
      this.showCAMControls = true;
      this.showSubmitDisclaimer = false;

      this.showIFrameApp = false;
      this.iFrameUrl = '';

      if (this.isSavedCardSelected(cardDetails)) {
        this.selectSavedCard();
      } else {
        this.selectedCard = cardDetails;
        this.isNewCardSelected = false;
        this.showSubmitDisclaimer = false;
        this.sendAlert.emit();
      }
    }
  }

  showSaveCard(): boolean {
    let showSaveCard: boolean = false;

    if (this.isCCProfileEnabled && !this.isPaymentInfoInError) {
      if (!this.savedInstrumentIdCode && this.showCAMControls) {
        showSaveCard = true;
      }
    }

    return showSaveCard;
  }

  toggleSaveCard(): void {
    this.saveCard = !this.saveCard;
    this.instrumentDetailsStorage.storeInstrumentForReuse = this.saveCard;
  }

  async getInstrumentDetails(instrumentIdCode: string, hideNonActionableAlert: boolean, paymentSource?: string): Promise<CreditCardInstrumentDetailsRs> {
    try {
      let request = PaymentsMethods.buildInstrumentIdRq(instrumentIdCode, paymentSource);
      let response: CreditCardInstrumentDetailsRs = await this.paymentsService.getPaymentInstrumentDetailsCC(request, !this.isGuestPayment);
      if (response && response.status) {
        this.isCardDeclined = false;
        return response;
      }
    } catch (error) {
      console.log(error.error);
      this.isCardDeclined = true;

      if (instrumentIdCode === this.savedInstrumentIdCode) {
        CAMStorage.setItemInStorage(CAMStorage.storageKeys.savedInstrumentIdCodeHasError, true);
      }

      this.displayInstrumentDetailsErrorAlert(error.error.additionalDetails, hideNonActionableAlert);
    } 
  }

  async enrollToAutoPay(instrumentIdCode: string, hideNonActionableAlert?: boolean): Promise<AutoPayRs> {
    try {
      this.isCardDeclined = false;
      const creditCardAutoPayRq = AutoPayMethods.buildAutoPayEnrollRequest(instrumentIdCode, JSON.parse(JSON.stringify(this.selectedBillingAccount)));
      const response: AutoPayRs =  await this.autoPayService.enrollCreditCardToAutoPay(creditCardAutoPayRq);

      return response;
    } catch (error) {
      const errorRs = error.error;
      console.log(errorRs);

      if (AutoPayMethods.isInstrumentDetailsError(errorRs.error)) {
        this.isCardDeclined = true;
        
        this.displayInstrumentDetailsErrorAlert(errorRs.error.instrumentErrorCode, hideNonActionableAlert);
      } 

      return errorRs;
    } 
  }


  setInstrumentDetailsErrorDisplay() {
    if (this.cardOptions && this.cardOptions.length && this.isNewCardSelected) {
      this.preselectCard();
      this.isNewCardSelected = false;

    }
  }

  async verifyInstrument(): Promise<void> {
    this.showIFrameApp = false;
    this.showCAMControls = false;
    this.showSpinner = true;

    this.selectedCard = await this.getInstrumentDetails(this.instrumentIdCode, false);

    if (this.isCardDeclined) {
      this.initializeCreditCardInput();
    } else if (this.isEditCardSelected) {
      await this.updateSavedCardDisplay();
    } else if (this.isNewCardSelected) {
      this.instrumentDetailsStorage.storeInstrumentForReuse = this.saveCard;

      if (this.saveCard) {
        PaymentsMethods.setSavedCardStorage(this.selectedCard.instrument.instrumentIdCode);
        this.instrumentDetailsStorage.isNewSavedCard = true;
      }

      this.sendCardDetails(this.selectedCard);
    }

    this.showSpinner = false;
  }

  async enrollCreditCardToAutoPay(): Promise<void> {
    this.showIFrameApp = false;
    this.showCAMControls = false;
    this.showSpinner = true;
    this.sendAlert.emit();      // clear existing errors on submit

    let result = await this.enrollToAutoPay(this.instrumentIdCode, false);

    if (this.isCardDeclined) {
      this.initializeCreditCardInput();
    } else {
      this.authorizeEvent.emit(result);
    }

    this.showSpinner = false;
  }

  async updateSavedCardDisplay(): Promise<void> {
    if (!this.isCardDeclined) {
      await this.updateSavedCCProfile();
      if (!this.cardUpdateHasError) {
        this.isCardUpdated = true;
        this.savedInstrumentIdCode = this.instrumentIdCode;
        this.savedCardDetails = this.selectedCard;
        this.instrumentDetailsStorage.cardInstrumentDetailsResponse = this.selectedCard;
        this.instrumentDetailsStorage.storeInstrumentForReuse = this.saveCard;

        CAMStorage.setItemInStorage(CAMStorage.storageKeys.instrumentDetailsStorage, this.instrumentDetailsStorage);
        PaymentsMethods.setSavedCardStorage(this.instrumentIdCode);

        this.setCardActionSuccessMessage();
        this.setCardActionSuccessDisplay();
      }
    }

    await this.initializeCreditCardInput();
  }

  setCardActionSuccessMessage(): void {
    let successMessage = `Success! Your saved credit card ${PaymentsMethods.getPaymentCardDisplay(this.savedCardDetails)} has been updated`;

    if (this.cardAction === this.cardActions.DELETE) {
      successMessage = `Success. Your saved credit card ${PaymentsMethods.getPaymentCardDisplay(this.savedCardDetails)} has been deleted`;
    }

    this.cardActionSuccessMessage = successMessage;
  }

  setCardActionSuccessDisplay(): void {
    this.showCardActionSuccess = true;
    this.showCardActionConfirmation = false;

    this.showSubmitDisclaimer = false;
    this.displayConfirmDelete = false;
  }

  async continueCC(cardDetails: CreditCardInstrumentDetailsRs): Promise<void> {
    const hasPaymentechProfile: boolean = this.instrumentDetailsStorage.hasPaymentechProfile;

    if (hasPaymentechProfile && this.isSavedCardSelected(cardDetails) && !this.isCardUpdated) {
      this.showSpinner = true;
      await this.updateSavedCCProfile();
    }

    this.sendCardDetails(cardDetails);
  }

  sendCardDetails(cardDetails: CreditCardInstrumentDetailsRs): void {
    if (cardDetails) {
      const eventResponse: InstrumentDetailsEvent = new InstrumentDetailsEvent();
      this.instrumentDetailsStorage.cardInstrumentDetailsResponse = this.selectedCard;
      this.instrumentDetailsStorage.storeInstrumentForReuse = this.saveCard;
      eventResponse.instrumentDetailsStorage = this.instrumentDetailsStorage;
      eventResponse.instrumentDetailsStorage.eftInstrumentDetailsResponse = null;
      this.sendAlert.emit();
      this.authorizeEvent.emit(eventResponse);
    }
  }

  async useNewCard(): Promise<void> {
    if (!this.showCardActionConfirmation) {
      this.isEditCardSelected = false;
    }
    this.showCardActionConfirmation = false;

    this.selectedCard = null;
    this.isNewCardSelected = true;

    await this.displayIFrameApp();
  }

  toggleCardAction(cardAction?: string): void {
    this.resetCardActionDisplay(cardAction);

    switch (cardAction) {
      case this.cardActions.EDIT:
        this.editSavedCard();
        break;
      case this.cardActions.DELETE:
        if (this.displayEditIFrameApp) {
          this.closeEditSavedCard();
        }
        this.showConfirmDelete();
        break;
      default:
        this.showCardActionConfirmation = false;
        break;
    }
  }

  resetCardActionDisplay(cardAction?: string): void {
    this.showCardActionConfirmation = true;
    this.showCardActionSuccess = false;
    this.showSubmitDisclaimer = false;
    this.cardAction = cardAction;

    if (!this.isSavedCardSelected(this.selectedCard)) {
      this.showIFrameApp = false;
      this.selectSavedCard();
    }

    this.sendAlert.emit();
  }

  async editSavedCard(): Promise<void> {
    this.isNewCardSelected = false;
    this.isEditCardSelected = true;
    this.displayEditIFrameApp = true;
    this.displayConfirmDelete = false;

    await this.displayIFrameApp();
  }

  async displayIFrameApp(): Promise<void> {
    if (!this.showIFrameApp) {
      this.sendAlert.emit();
      await this.buildHydraCreditCardIFrame();
      this.showSubmitDisclaimer = (!this.isRCC && this.iFrameUrl && !this.selectedCard) ? true : false;
    }
  }

  closeEditSavedCard(): void {
    this.displayEditIFrameApp = false;
    this.isEditCardSelected = false;
    this.showIFrameApp = false;
    this.showSubmitDisclaimer = false;
    this.showCardActionConfirmation = false;
    this.isNewCardSelected = false;
    this.cardAction = '';
  }

  showConfirmDelete(): void {
    this.showCardActionConfirmation = true;
    this.displayConfirmDelete = true;
    this.displayEditIFrameApp = false;
  }

  async updateSavedCCProfile(): Promise<void> {
    if (this.selectedCard) {
      this.cardUpdateHasError = false;
      try {
        const updateCardRq: SaveCreditCardProfileRq = this.buildUpdateSavedCreditCardProfileRequest();
        await this.paymentsService.updateCreditCardProfile(updateCardRq);
      } catch (error) {
        console.log(error);
        this.cardUpdateHasError = true;
        this.sendAlert.emit(PaymentsMethods.makeErrorAlertMessage());
      }
    }
  }

  buildUpdateSavedCreditCardProfileRequest(): SaveCreditCardProfileRq {
    const updateCardRq: SaveCreditCardProfileRq = new SaveCreditCardProfileRq();
    updateCardRq.accountNumbers = this.getCCProfileBillingAccounts();
    updateCardRq.creditCardProfileToSave = PaymentsMethods.buildCCInstrumentFromDetails(this.selectedCard, false);
    updateCardRq.requestGuid = this.instrumentDetailsStorage.requestGuid ? this.instrumentDetailsStorage.requestGuid : '';
    updateCardRq.emailAddress = this.instrumentDetailsStorage.emailAddress;

    return updateCardRq;
  }

  async deleteSavedCard(): Promise<void> {
    if (this.savedInstrumentIdCode) {
      this.showSpinner = true;
      await this.deleteSavedCCProfile();
      if (!this.cardDeletionHasError) {
        if (this.isSavedCardSelected(this.instrumentDetailsStorage.cardInstrumentDetailsResponse)) {
          this.instrumentDetailsStorage.cardInstrumentDetailsResponse = null;
          this.savedInstrumentIdCode = "";
          CAMStorage.setItemInStorage(CAMStorage.storageKeys.instrumentDetailsStorage, this.instrumentDetailsStorage);
        }

        this.setCardActionSuccessMessage();
        this.setCardActionSuccessDisplay()
        this.resetSavedCardDetails();
        await this.configureCreditCardDisplay();
      } else {
        this.showSubmitDisclaimer = false;
        this.displayConfirmDelete = false;
      }

      this.showSpinner = false;
    }
  }

  async deleteSavedCCProfile(): Promise<void> {
    try {
      let deleteCardReq = this.buildDeleteCreditCardProfileReq();
      await this.paymentsService.deleteCreditCardProfile(deleteCardReq);

      CAMStorage.removeItemInStorage(CAMStorage.storageKeys.savedInstrument);
      CAMStorage.setItemInStorage(CAMStorage.storageKeys.savedInstrumentIdCodeHasError, false);
    } catch (error) {
      this.cardDeletionHasError = true;
      this.sendAlert.emit(PaymentsMethods.makeErrorAlertMessage());
    }
  }

  buildDeleteCreditCardProfileReq(): DeleteCreditCardProfileRq {
    const deleteCardReq: DeleteCreditCardProfileRq = {
      accountNumbers: this.getCCProfileBillingAccounts(),
      requestGuid: this.instrumentDetailsStorage.requestGuid ? this.instrumentDetailsStorage.requestGuid : ""
    };

    return deleteCardReq;
  }

  getCCProfileBillingAccounts(): string[] {
    let accountNumbers: string[] = [];

    if (this.instrumentDetailsStorage.cCProfileAccounts) {
      this.instrumentDetailsStorage.selectedAccounts.forEach(sa => {
        accountNumbers.push(sa.billingAccountNumber);
      });

      this.instrumentDetailsStorage.cCProfileAccounts.forEach(cca => {
        if (!accountNumbers.includes(cca.billingAccountNumber)) {
          accountNumbers.push(cca.billingAccountNumber);
        }
      });

      return accountNumbers;
    }
  }
  
  resetSavedCardDetails(): void {
    this.savedCardDetails = null;
    this.selectedCard = null;
  }

  displayInstrumentDetailsErrorAlert(instrumentErrorCode: string, hideNonActionableAlert?: boolean): void {
    let alert = PaymentsMethods.buildInstrumentDetailsErrorAlert(instrumentErrorCode, hideNonActionableAlert);

    if (alert) {
      this.sendAlert.emit(alert);
    }
  }

  cancelCC(): void {
    if (this.showCardOptions) {
      if (this.showIFrameApp) {
        this.cancelEvent.emit(true);      // reset payment method display
      } else {
        this.cancelEvent.emit(false);     // navigate backwards
      }
    } else {
      this.cancelEvent.emit(false);       // navigate backwards
    }
  }

  private getInstrumentIdCode(response: CreditCardInstrumentDetailsRs): string {
    let instrumentIdCode: string = '';

    if (response && response.instrument) {
      instrumentIdCode = response.instrument.instrumentIdCode;
    }

    return instrumentIdCode;
  }

  isSavedCardSelected(cardDetails: CreditCardInstrumentDetailsRs): boolean {
    let isSavedInstrumentSelected: boolean = false;

    if (this.getInstrumentIdCode(cardDetails) === this.savedInstrumentIdCode) {
      isSavedInstrumentSelected = true;
    }

    return isSavedInstrumentSelected;
  }

  setButtonAttributes(): void {
    if (this.isGuestPayment) {
      this.nextButtonText = 'Next';
      this.backButtonText = 'Back';
      this.backButtonId = 'PWOL_ccCancel';
    } else {
      this.nextButtonText = 'Continue';
      this.backButtonText = 'Cancel';
      this.backButtonId = this.isRCC ? 'RCC_ccCancel' : 'MAP_ccCancel';
    }
  }

  getRCCPayLessMessage(): string {
    let payLessMessage: string = "Pay less in installment fees - ";

    if (this.isRegularPaymentMethod) {
      payLessMessage += " enroll with ";
    } else if (this.isRCCPaymentMethod) {
      payLessMessage += " switch to ";
    } else {
      payLessMessage += " stay enrolled with ";
    }

    payLessMessage += "your bank account (EFT).";

    return payLessMessage;
  }

  openCCAuthorizationModal(): void {
    this.modalService.openModal(ModalNames.CC_AUTHORIZATION);
  }
}
