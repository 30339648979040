export default class CrossSellOffer {
    agency: CrossSellAgency;
    crossSellPolicies: CrossSellPolicy[];
    customer: CrossSellCustomer;
    lobOffer: string;
    offerAvailable: boolean;
    recommendationId: string;
    recommendations: CrossSellRecomendations[];
    saveCustomer: CrossSellRecomendations;
    savings: number;
    sessionReferenceId: string;
    sourcePolicyNumber: string;
    sourceLob: string;
    sourceLobId: number;
    statusCode: number;
    statusDescription: string;
    user: string;
}

export class CrossSellPolicy {
    lineOfBusiness: string;
    policyNumber: string;
    ratingState: string;
}

export class CrossSellAgency {
    agencyName: string;
    agencyPhone: string;
    agencyEmail: string;
    goldAgency: boolean;
    goldPlusAgency: boolean;    
}

export class CrossSellCustomer {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
}

export enum DISPOSITION_EVENT {
    offerClicked = 5,
    offerLobAlreadyExists = 6,
    offerGenricOverride = 7,
    offerDisplayed = 8
    //offerNewBusinessOverride = 9,
    //offerRenewalOverride = 10,
}


// Trove v3 API
export type CrossSellRs = {
    recommendations: CrossSellRecomendations[];
}

export type CrossSellRecomendations = {
    applicableEntityId: string;
    applicableEntityType: string;
    id: string;
    name: string;
    recommend: boolean;
    score: number;
}

export type CrossSellVariables = {
    policyId: string;
    userId: string;
    customerId: string;
    recommendationRequests: RecommendationRequest[];
}

type RecommendationRequest = {
    name: CrossSellOffers;
}

export enum CrossSellOffers {
    SAVE_CUSTOMER = "SAVE_CUSTOMER",
    SELL_AUTO_POLICY = "SELL_AUTO_POLICY",
    SELL_HOME_POLICY = "SELL_HOME_POLICY",
    SELL_RENTERS_POLICY = "SELL_RENTERS_POLICY",
    SELL_EARTHQUAKE_POLICY = "SELL_EARTHQUAKE_POLICY",
    SELL_UMBRELLA_POLICY = "SELL_UMBRELLA_POLICY",
    SELL_MOTORCYCLE_POLICY = "SELL_MOTORCYCLE_POLICY",
    SELL_WATERCRAFT_POLICY = "SELL_WATERCRAFT_POLICY"
}

export class DispositionRs {
    addDisposition: AddDisposition;
}

class AddDisposition {
    addedDisposition: AddedDisposition;
}

class AddedDisposition {
    id: string;
    value: string;
    customerDisposition: string;
    insertDateTime: number;
}

export type DispositionVariables = {
    addDispositionDto: AddDispositionRequestDto;
}

type AddDispositionRequestDto = {
    recommendationId: string;
    disposition: Dispositions;
}

export enum Dispositions {
    clicked = "CLICKED",
    suppressed = "SUPPRESSED",
    displayed = "DISPLAYED",
    accepted = "ACCEPTED" // Used for DNS list
}
