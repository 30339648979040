import Endpoints from "../constants/endpoints";
import { HYDRA_PAYMENT_STATUS_CODES, PAYMENTS_STATUS, HYDRA_DECLINED_MESSAGE } from "../constants/paymentsStatus";
import { Alert, AlertType } from "../models/alert";
import { BillingPaymentAccount, BillingPaymentAccountResponse } from "../models/billingPaymentAccount";
import { AccountsWithAmountToPay } from "../models/paymentInfo";
import { PAYMENT_STATUS_CODES } from 'src/app/utilities/constants/payment';
import {
  EFTInstrument,
  EFTInstrumentDetailsRs,
  InstrumentDetailsStorage,
  MakePaymentsRq,
  PendingPaymentStorageAccounts,
  InstrumentIdRq,
  SavedInstrument,
  CreditCardInstrument,
  MakePaymentRs,
  CreditCardInstrumentDetailsRs
} from "../models/payments";

import DateMethods from "./date.methods";
import CAMStorage from "../constants/CAMStorage";
import { PaymentSource } from "../constants/payment";
import Billing from "../models/billing";
import { PaymentMethodDisplay, PaymentMethods } from "../constants/paymentMethods";
import { CreditCardInfo } from "../models/creditCard";
import EFTInfo from "../models/eftInfo";
import { HttpStatusCode } from "@angular/common/http";

export default class PaymentsMethods {
  public static setSavedCardStorage(instrumentIdCode: string, paymentSource?: string): void {
    const savedCard: SavedInstrument = new SavedInstrument();
    savedCard.code = instrumentIdCode;
    savedCard.paymentSource = paymentSource ? paymentSource : PaymentSource.HYDRA_ADYEN;

    CAMStorage.setItemInStorage(CAMStorage.storageKeys.savedInstrument, savedCard);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.savedInstrumentIdCodeHasError, false);
  }

  public static createCCPaymentsRq(instrumentDetailsStorage: InstrumentDetailsStorage): any {
    let paymentsRq: MakePaymentsRq = new MakePaymentsRq();

    paymentsRq.instrument = this.buildCCInstrumentFromDetails(instrumentDetailsStorage.cardInstrumentDetailsResponse, instrumentDetailsStorage.storeInstrumentForReuse, instrumentDetailsStorage.customerName);
    paymentsRq.accountsWithAmountToPay = this.configureAccountsWithAmountToPay(instrumentDetailsStorage.selectedAccounts);
    paymentsRq.requestGuid = instrumentDetailsStorage.requestGuid;

    return paymentsRq;
  }

  public static buildCCInstrumentFromDetails(cardInstrumentDetailsResponse: CreditCardInstrumentDetailsRs, storeInstrumentForReuse: boolean, customerName?: string): CreditCardInstrument {
    const instrument: CreditCardInstrument = new CreditCardInstrument();

    instrument.instrumentIdCode = cardInstrumentDetailsResponse.instrument.instrumentIdCode;
    instrument.maskedInstrument = cardInstrumentDetailsResponse.instrument.maskedInstrument;
    instrument.cardBrand = cardInstrumentDetailsResponse.instrument.cardBrand;
    instrument.cardExpirationMonth = cardInstrumentDetailsResponse.instrument.cardExpirationMonth;
    instrument.cardExpirationYear = cardInstrumentDetailsResponse.instrument.cardExpirationYear;
    instrument.instrumentHolderName = this.setCardholderName(cardInstrumentDetailsResponse.instrument.instrumentHolderName, customerName);
    instrument.storeInstrumentForReuse = storeInstrumentForReuse;

    return instrument;
  }

  private static setCardholderName(instrumentCardholderName: string, customerName: string) {
    let name = "";

    if (instrumentCardholderName) {
      name = instrumentCardholderName;
    } else if (customerName) {
      name = customerName;
    }

    return name;
  }

  public static createEFTPaymentsRequest(instrumentDetailsStorage: InstrumentDetailsStorage): MakePaymentsRq {
    let makePaymentsRq: MakePaymentsRq = new MakePaymentsRq();

    makePaymentsRq.instrument = this.buildEFTInstrumentFromDetails(instrumentDetailsStorage.eftInstrumentDetailsResponse, instrumentDetailsStorage.storeInstrumentForReuse);
    makePaymentsRq.accountsWithAmountToPay = this.configureAccountsWithAmountToPay(instrumentDetailsStorage.selectedAccounts);
    makePaymentsRq.requestGuid = instrumentDetailsStorage.requestGuid;

    return makePaymentsRq;
  }

  public static buildEFTInstrumentFromDetails(eftInstrumentDetailsResponse: EFTInstrumentDetailsRs, storeInstrumentForReuse: boolean): EFTInstrument {
    const instrument: EFTInstrument = new EFTInstrument();

    instrument.bankRoutingNumber = eftInstrumentDetailsResponse.instrument.bankRoutingNumber;
    instrument.paymentAccountToken = eftInstrumentDetailsResponse.instrument.paymentAccountToken;
    instrument.instrumentIdCode = eftInstrumentDetailsResponse.instrument.instrumentIdCode;
    instrument.maskedInstrument = eftInstrumentDetailsResponse.instrument.maskedInstrument;
    instrument.instrumentHolderName = eftInstrumentDetailsResponse.instrument.instrumentHolderName;
    instrument.bankAccountType = eftInstrumentDetailsResponse.instrument.bankAccountType;

    return instrument;
  }

  public static buildInstrumentIdRq(instrumentIdCode: string, paymentSource?: string): InstrumentIdRq {
    const instrumentIdRq = new InstrumentIdRq();
    instrumentIdRq.instrumentIdCode = instrumentIdCode;
    instrumentIdRq.paymentSource = paymentSource ? paymentSource : PaymentSource.HYDRA_ADYEN;
    return instrumentIdRq;
  }

  private static configureAccountsWithAmountToPay(selectedAccounts: BillingPaymentAccount[]): AccountsWithAmountToPay[] {
    const accountsWithAmountToPay: AccountsWithAmountToPay[] = [];

    if (selectedAccounts && selectedAccounts.length) {
      selectedAccounts.forEach((selectedAccount: BillingPaymentAccount) => {
        const accountToPay: AccountsWithAmountToPay = new AccountsWithAmountToPay();

        accountToPay.amountToPay = this.getAmountToPay(selectedAccount);
        accountToPay.billingAccountNumber = selectedAccount.billingAccountNumber;
        accountToPay.policyNumber = selectedAccount.policyNumber;

        accountsWithAmountToPay.push(accountToPay);
      });
    }

    return accountsWithAmountToPay;
  }

  public static getAmountToPay(account: BillingPaymentAccount): string {
    let amountToPay = "";

    if (account) {
      if (account.amountToPayDisplay) {
        amountToPay = account.amountToPayDisplay;
      } else {

        if (account.isAmountToPayCurrentAmountDue) {
          amountToPay = account.amountDue + "";
        } else if (account.isAmountToPayTotalBalance) {
          amountToPay = account.totalBalanceValue + "";
        }

      }
    }

    return amountToPay;
  }

  public static buildPaymentResponsesForStorage(instrumentDetailsStorage: InstrumentDetailsStorage, makePaymentResults: MakePaymentRs[]): InstrumentDetailsStorage {

    instrumentDetailsStorage.selectedAccounts.forEach((selectedAccount: BillingPaymentAccount) => {

      if (makePaymentResults && makePaymentResults.length) {    
        const paymentResult: MakePaymentRs = this.getPaymentForSelectedAccount(selectedAccount, makePaymentResults);

        const billingPaymentAccountResponse: BillingPaymentAccountResponse = new BillingPaymentAccountResponse();

        if (paymentResult) {
          billingPaymentAccountResponse.statusCode = paymentResult.paymentStatus;
          billingPaymentAccountResponse.statusMessage = paymentResult.paymentAdditionalDetails;
          billingPaymentAccountResponse.paymentConfirmationNumber = paymentResult.confirmationNumber;

          selectedAccount.paymentConfirmationNumber = paymentResult.confirmationNumber;
        } else {
          billingPaymentAccountResponse.statusCode = PAYMENTS_STATUS.ERROR;
        }

        selectedAccount.paymentResponse = billingPaymentAccountResponse;

      }
    });

    instrumentDetailsStorage.selectedAccounts.sort((a, b) => this.sortPaymentAccountsByStatusCodeDesc(a.paymentResponse?.statusCode, b.paymentResponse.statusCode));

    return instrumentDetailsStorage;
  }

  public static sortPaymentAccountsByStatusCodeDesc(statusCodeA: number, statusCodeB: number): number {
    
    if (isNaN(statusCodeA) && isNaN(statusCodeB)) {
      return 0;
    }

    // list errored accounts first
    if (statusCodeA > statusCodeB) {
      return -1;
    } else if (statusCodeA < statusCodeB) {
      return 1;
    } else {
      return 0;
    }
  }

  public static isMakePaymentInError(account: BillingPaymentAccount): boolean { 
    let isError: boolean = false;

    if (account) {
      const makePaymentStatusCode: number = account.paymentResponse && account.paymentResponse.statusCode ? account.paymentResponse.statusCode : 0;

      if (makePaymentStatusCode > PAYMENTS_STATUS.APPROVED && makePaymentStatusCode !== PAYMENTS_STATUS.REALTIME_PAYMENT_ERROR) {
        isError = true;
      }
  
      if (isError) {
        if (makePaymentStatusCode === PAYMENT_STATUS_CODES.duplicatePayment) {
          account.displayDuplicatePaymentError = true;
        } else {
          account.displayDefaultPaymentError = true;
        }
      }
    }

    return isError;
  }

  public static createPartialPaymentErrorAlert(isMakePayment: boolean): Alert {
    const partialErrorAlert: Alert = new Alert(AlertType.NEGATIVE);
    let errorMessage = `One or more of your payments couldn't be `;

    if (isMakePayment) {
      errorMessage += 'processed. Please try again. ';
      errorMessage += `{ "text" : "Pay now", "url" : "${Endpoints.url.payment}", "heapId" : "PaymentConfirm_acts_link_partial_failure" }`;
    } else {
      errorMessage += 'scheduled.';
    }

    partialErrorAlert.messages = [errorMessage];

    return partialErrorAlert;
  }

  private static getPaymentForSelectedAccount(selectedAccount: BillingPaymentAccount, paymentsRs: MakePaymentRs[]): MakePaymentRs {
    const paymentAccountRs: MakePaymentRs = paymentsRs.find((result) =>
      result.billingAccountNumber === selectedAccount.billingAccountNumber
    );

    return paymentAccountRs;
  }  

  public static getPaymentCardDisplay(instrumentDetails: CreditCardInstrumentDetailsRs, showExpiration: boolean = true, showBrand: boolean = true): string {
    if (instrumentDetails && instrumentDetails.instrument) {
      let display = '';

      if (showBrand) {
        display = `${instrumentDetails.instrument.cardBrand} *${instrumentDetails.instrument.maskedInstrument}`;
      } else {
        display = `*${instrumentDetails.instrument.maskedInstrument}`;
      }


      if (showExpiration) {
        const month = instrumentDetails.instrument.cardExpirationMonth;
        const year = DateMethods.formatShortYear(instrumentDetails.instrument.cardExpirationYear);
        display += ` Exp. ${month}/${year}`;
      }
      return display;
    }
  }

  public static isHydraInstrumentUsed(instrumentDetailsStorage: InstrumentDetailsStorage): boolean {
    let isHydraInstrumentUsed: boolean = false;

    if (instrumentDetailsStorage && (instrumentDetailsStorage.cardInstrumentDetailsResponse || instrumentDetailsStorage.eftInstrumentDetailsResponse)) {
      isHydraInstrumentUsed = true;
    }

    return isHydraInstrumentUsed;
  }

  public static isPendingCreditCardPayment(statusCode: number) {
    if (statusCode === PAYMENTS_STATUS.REALTIME_PAYMENT_ERROR) {
      return true;
    }

    return false;
  }

  public static isAnyEFTPaymentDuplicate(makePaymentResults: MakePaymentRs[]): boolean {
    let isAnyDuplicate: boolean = false;

    if (makePaymentResults && makePaymentResults.length) {
      isAnyDuplicate = makePaymentResults.some(paymentResult => paymentResult.paymentStatus === PAYMENT_STATUS_CODES.duplicatePayment);
    }

    return isAnyDuplicate;
  }

  public static setEFTDuplicatePaymentAccounts(instrumentDetailsStorage: InstrumentDetailsStorage, makePaymentResults: MakePaymentRs[]): InstrumentDetailsStorage {
    // for duplicate payment display on payment review page
    instrumentDetailsStorage.selectedAccounts.forEach((selectedAccount: BillingPaymentAccount) => {
      if (makePaymentResults && makePaymentResults.length) {
        const paymentResult: MakePaymentRs = this.getPaymentForSelectedAccount(selectedAccount, makePaymentResults);

        const billingPaymentAccountResponse: BillingPaymentAccountResponse = new BillingPaymentAccountResponse();

        if (paymentResult && paymentResult.paymentStatus === PAYMENT_STATUS_CODES.duplicatePayment) {
          selectedAccount.displayDuplicatePaymentError = true;
          selectedAccount.accountMessage = paymentResult.paymentAdditionalDetails;
        } else {
          selectedAccount.displayDefaultPaymentError = true;
          billingPaymentAccountResponse.statusCode = PAYMENTS_STATUS.ERROR;
        }

        selectedAccount.paymentResponse = billingPaymentAccountResponse;
      }
    });

    instrumentDetailsStorage.selectedAccounts.sort((a, b) => this.sortPaymentAccountsByStatusCodeDesc(a.paymentResponse?.statusCode, b.paymentResponse?.statusCode));

    return instrumentDetailsStorage;
  }

  public static createErrorAlertForMakePaymentSubmit(responseStatusDescription: string, isGuestPayment?: boolean, statusCode?: number): Alert {
    const alert: Alert = new Alert(AlertType.NEGATIVE);
    const message = this.getMakePaymentErrorMessage(isGuestPayment, statusCode);

    alert.messages = [message];
    const heapIdPage = isGuestPayment ? 'GuestPaymentReview' : 'HydraReview';
    alert.heapId = `MMA-View-NotificationsPayment|${heapIdPage}|${responseStatusDescription}`;

    return alert;
  }

  private static getMakePaymentErrorMessage(isGuestPayment: boolean, statusCode: number): string {
    const linkHeapIdPrefix: string = isGuestPayment ? 'GuestPayment_acts_link_error' : 'PaymentReview_acts_link_error_';
    const reviewInformationMsg: string = isGuestPayment ? 'review your information' : `{ "text" : "review your information", "url" : "${Endpoints.url.paymentMethod}", "heapId" : "${linkHeapIdPrefix}payment_method" }`;
    let message = `Oh dear, something went wrong. Please ${reviewInformationMsg}, and try again.`;

    switch (statusCode) {
      case PAYMENT_STATUS_CODES.duplicatePayment:
         message = "We have already received a payment with this amount. See below.";
         break;
      default:
         break;
    }

    return message;
  }

  public static createDeclinedAlert(instrumentErrorCode: string): Alert {
    const declinedAlert = new Alert(AlertType.NEGATIVE);
    let message = 'Oh dear, something went wrong. Please try again.';

    if (this.displayDeclinedError(instrumentErrorCode)) {
      message = HYDRA_DECLINED_MESSAGE.find((declineMessage: any) => declineMessage.key === instrumentErrorCode)?.value;
    }

    declinedAlert.messages = [message];

    return declinedAlert;
  }

  public static displayDeclinedError(hydraCode: string): boolean {
    let displayError = false;

    if (hydraCode) {
      switch (hydraCode) {
        case HYDRA_PAYMENT_STATUS_CODES.insufficientFunds:
        case HYDRA_PAYMENT_STATUS_CODES.limitExceeded:
        case HYDRA_PAYMENT_STATUS_CODES.cardholderActivityLimitExceeded:
        case HYDRA_PAYMENT_STATUS_CODES.expiredCard:
        case HYDRA_PAYMENT_STATUS_CODES.invalidCreditCardNumber:
        case HYDRA_PAYMENT_STATUS_CODES.invalidExpirationDate:
        case HYDRA_PAYMENT_STATUS_CODES.declinedCVV:
          displayError = true;
          break;
        default:
          break;
      }
    }

    return displayError;
  }

  public static allowNavToPaymentConfirmation(statusCode: number): boolean {
    let navToConfirmation: boolean = false;

    switch (statusCode) {
      case PAYMENTS_STATUS.APPROVED:
      case PAYMENTS_STATUS.HAS_AT_LEAST_ONE_HYDRA_ERROR:
      case PAYMENTS_STATUS.HAS_AT_LEAST_ONE_RTB_PENDING:
      case PAYMENTS_STATUS.FAILED_SAVE_CC:
      case PAYMENTS_STATUS.REALTIME_PAYMENT_ERROR:
        navToConfirmation = true;
        break;
      default:
        break;
    }

    return navToConfirmation;
  }

  public static allowNavToMakePaymentConfirmation(statusCode: number): boolean {
    let navToConfirmation: boolean = false;
    switch (statusCode) {
      case HttpStatusCode.Ok:
      case HttpStatusCode.MultiStatus:
        navToConfirmation = true;
        break;
      default:
        break;
    }
    return navToConfirmation;
  }

  public static buildPendingPaymentMessage(paymentInfo: PendingPaymentStorageAccounts): string {
    let message = "";

    if (paymentInfo && paymentInfo.amountPaid) {
      message = `Your payment of $${paymentInfo.amountPaid.toFixed(2)}  was successful, and your account should update soon.`;
    }

    return message;
  }

  public static clearAllMakePaymentStorage(destinationUrl: string) {
    if (!this.isValidMakePaymentFlowUrl(destinationUrl)) {
      CAMStorage.removeItemInStorage(CAMStorage.storageKeys.instrumentDetailsStorage);
      CAMStorage.removeItemInStorage(CAMStorage.storageKeys.savedInstrument);
      CAMStorage.removeItemInStorage(CAMStorage.storageKeys.savedInstrumentIdCodeHasError);
    }
  }

  public static isValidMakePaymentFlowUrl(destinationUrl: string): boolean {
    switch (destinationUrl) {
      case Endpoints.url.payment:
      case Endpoints.url.paymentMethod:
      case Endpoints.url.paymentReview:
      case Endpoints.url.paymentConfirmation:
        return true;
      default:
        return false;
    }
  }

  public static buildInstrumentDetailsErrorAlert(instrumentErrorCode: string, hideNonActionableAlert?: boolean): Alert {
    let alert = hideNonActionableAlert ? null : this.makeErrorAlertMessage();

    if (!hideNonActionableAlert) {
      alert = this.createDeclinedAlert(instrumentErrorCode);
    }

    return alert;
  }

  public static makeErrorAlertMessage(): Alert {
    const errorAlert = new Alert(AlertType.NEGATIVE);
    errorAlert.messages = ["Oh dear, something went wrong. Please try again."];

    return errorAlert;
  }

  public static getPaymentMethodDisplay(billingAccount: Billing): string {
    let paymentMethodDisplay: string = '';

    if (billingAccount && billingAccount.paymentMethod) {
      const paymentMethod: string = billingAccount.paymentMethod.toUpperCase();

      switch (paymentMethod) {
        case PaymentMethods.REGULAR:
          return PaymentMethodDisplay.MANUAL;
        case PaymentMethods.AUTOMATIC_DEDUCTION:
          return this.getREFTDisplay(billingAccount.recurrEFTData);
        case PaymentMethods.RECURRING_CREDIT_CARD:
          return this.getRCCDisplay(billingAccount.recurrCreditCardData);
        case PaymentMethods.MORTGAGEE_PAY:
          return PaymentMethodDisplay.MORTGAGEE_PAY;
        case PaymentMethods.PAYROLL_DEDUCTION:
          return PaymentMethodDisplay.PAYROLL_DEDUCTION;
        default:
          return paymentMethod;
      }
    }
    
    return paymentMethodDisplay;
  }

  public static getRCCDisplay(RCCInfo: CreditCardInfo): string {
    let RCCDisplay: string = '';

    // should also be displayed with CC icon when used in html
    if (RCCInfo && RCCInfo.cardLastFour) {
      RCCDisplay = RCCInfo.cardLastFour;
    }

    return RCCDisplay;
  }

  public static getREFTDisplay(EFTInfo: EFTInfo): string {
    let REFTDisplay: string = '';

    if (EFTInfo && EFTInfo.accountType && EFTInfo.displayMaskedAccountNumber) {
      if (EFTInfo.accountType === 'C') {
        REFTDisplay += 'Checking ';
      } else if (EFTInfo.accountType === 'S') {
        REFTDisplay += 'Savings ';
      }

      REFTDisplay += EFTInfo.displayMaskedAccountNumber;
    }

    return REFTDisplay;
  }
}   
