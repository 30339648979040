export default class DocumentMeta {
    contentId: string;
    contentUrl: string;
    documentByteArray: string;
    documentCode: string;
    documentDate: string;
    documentMonth: number;
    documentYear: number;
    receivedPassDate: string;
    documentDisplayName: string;
    documentType: string;
    documentUrl: string;
    downloadUrl: string;
    primaryKey: string;
    documentTypeCode: string;
    documentDescription: string;
    documentTypeDescription: string;
}
