@if (currentScheduledPayment && schedulePaymentMessage) {
    <div class="scheduled-payment" [ngClass]="{'failed-payment' : isFailed, 'small' : parentHeapId === 'LP_billTile', 'no-margin-bottom' : parentHeapId === 'MAP'}">
    @if (isFailed) {
        <div *ngIf="isFailed; else notificationIcon">
            <app-icon name="info" color="error"></app-icon>
        </div>
    } @else {
        <div>
            <app-icon name="info" color="informative"></app-icon>
        </div>
    }
    <div class="scheduled-payment-content">
        <b class="scheduled-make-payments-message">
            <span>{{schedulePaymentMessage}} </span>
        </b>
        <span>
            @if (isCancelScheduledPaymentLaunched && isCancelable) {
                <a class="sco-link sco-textLink sco--standalone schedule-make-payment-cancel" [id]="cancelLinkHeapId"
                (click)="openCancelSchedulePaymentModal()" [routerLink]="[]">
                Cancel
                </a>
            }
        </span>
    </div>
</div>
}
