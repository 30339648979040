import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, APP_BASE_HREF, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient, withFetch } from '@angular/common/http';

import { AuthenticationModule } from './authentication/authentication.module';
import { AppRoutingModule } from './app-routing.module';
import { GraphQLModule } from './graphql.module';
import { SharedModule } from './modules/shared/shared.module';

import { RequestCache } from 'src/app/utilities/interceptors/request-cache.service';
import { RequestInterceptor } from './utilities/interceptors/request-interceptor.service';

import { AppComponent } from './app.component';
import { PageMissingComponent } from './modules/error/components/pageMissing/pageMissing.component';
import { CallbackComponent } from './modules/onload/callback/callback.component';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    PageMissingComponent,
    CallbackComponent
  ],
  imports: [
    AppRoutingModule,
    AuthenticationModule,
    BrowserModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    SharedModule
  ],
  exports: [],
  providers: [
    provideHttpClient(withFetch()),
    RequestCache,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
    Title,
    DatePipe,
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
