import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { PAYMENTS_STATUS_DESCRIPTION } from 'src/app/utilities/constants/paymentsStatus';
import Endpoints from 'src/app/utilities/constants/endpoints';

import {
  DeleteCreditCardProfileRq,
  DeleteCreditCardProfileRs,
  InitiateInstrument,
  InitiateInstrumentRq,
  MakeEFTPaymentsRs,
  InstrumentIdRq,
  MakePaymentsRq,
  SaveCreditCardProfileRq,
  SaveCreditCardProfileRs,
  ScheduleMakePaymentRq,
  ScheduleMakePaymentRs,
  CancelScheduledPaymentsRq,
  EFTInstrumentDetailsRs,
  BaseMakePaymentsRs,
  CreditCardInstrumentDetailsRs,
} from 'src/app/utilities/models/payments';
import { ApiResponseStatus } from 'src/app/utilities/models/apiResponseStatus';

import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { RequestCache } from 'src/app/utilities/interceptors/request-cache.service';
import { BillingService } from '../billing-service/billing.service';

@Injectable({
  providedIn: 'root'
})

export class PaymentsService {
  initiateInstrumentObs: Observable<any> = null;
  getInstrumentDetailsCCObs: Observable<any> = null;
  getInstrumentDetailsEFTObs: Observable<any> = null;
  updateCreditCardProfileObs: Observable<any> = null;
  deleteCreditCardProfileObs: Observable<any> = null;
  makeCreditCardPaymentsObs: Observable<any> = null;
  makeEFTPaymentsObs: Observable<any> = null;
  getScheduledMakePaymentObs: Observable<any> = null;
  cancelScheduledPaymentsObs: Observable<any> = null;

  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private requestCache: RequestCache,
    private location: Location,
    private analyticsService: AnalyticsService,
    private billingService: BillingService
  ) { }

  async initiatePaymentInstrument(instrumentType: string, billingAccountNumber: string, isAuth: boolean): Promise<InitiateInstrument> {
    const errorHeapId = `MMA-View-NotificationSystemError|${instrumentType}${!isAuth ? '|GuestPayment' : ''}|InitiateInstrument`
    const path = this.location.path();
    const initiateEndpoint = isAuth ? Endpoints.api.initiatePaymentInstrument : Endpoints.api.initiateGuestPaymentInstrument;
    const request: InitiateInstrumentRq = this.buildInitiateInstrumentRequest(instrumentType, billingAccountNumber);
    this.requestCache.cacheBust(initiateEndpoint);

    this.initiateInstrumentObs = this.http.post<InitiateInstrument>(initiateEndpoint, request, { withCredentials: true }).pipe(share());

    return new Promise((resolve, reject) => {
        this.initiateInstrumentObs.subscribe({
          next: (response: InitiatePaymentInstrumentRs) => {
            if (response && response.status && response.status.code === 0) {
              if (response.result && this.isSuccessfulInitiateInstrument(response.result)) {
                resolve(response.result);
              } else {
                reject();
              }
            } else {
              this.analyticsService.trackAnalytics(errorHeapId);
              reject();
            }
          },
          error: (error) => {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: error });
          }
        });
      });
  }

  private buildInitiateInstrumentRequest(instrumentType: string, billingAccountNumber: string): InitiateInstrumentRq {
    let request = new InitiateInstrumentRq();
    request.instrumentType = instrumentType;
    request.billingAccountNumber = billingAccountNumber;

    return request;
  }

  private isSuccessfulInitiateInstrument(initiateInstrument : InitiateInstrument): boolean {
    let isSuccessfulInitiateInstrument = false;

    if (initiateInstrument && initiateInstrument.instrumentHostedPageUrl && initiateInstrument.instrumentIdCode) {
      isSuccessfulInitiateInstrument = true;
    }

    return isSuccessfulInitiateInstrument;
  }

  async getPaymentInstrumentDetailsCC(instrumentIdRq: InstrumentIdRq, isAuth: boolean): Promise<CreditCardInstrumentDetailsRs> {
    let errorHeapId = isAuth ? 'MMA-View-NotificationSystemError|GetInstrumentDetailsCC' : 'MMA-View-NotificationSystemError|GuestPayment|GetInstrumentDetailsCC';
    const path = this.location.path();
    const getEndpoint = isAuth ? Endpoints.api.getPaymentInstrumentDetailsCC : Endpoints.api.getGuestPaymentInstrumentDetailsCC;

    this.getInstrumentDetailsCCObs = this.http.post<CreditCardInstrumentDetailsResponse>(getEndpoint, instrumentIdRq, { withCredentials: true }).pipe(share());

    return new Promise((resolve, reject) => {
      this.getInstrumentDetailsCCObs.subscribe({
        next: (response: CreditCardInstrumentDetailsResponse) => {
          this.requestCache.cacheBust(getEndpoint);

          if (response && response.result.status && response.result.status === HttpStatusCode.Ok) {
            if (response.result) {
              resolve(response.result);
            }     
          } else {
            if (response.result.description  === PAYMENTS_STATUS_DESCRIPTION.DECLINED) {
              errorHeapId += '|Declined';
            }

            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: response.result });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  async getPaymentInstrumentDetailsEFT(instrumentIdRq : InstrumentIdRq, isAuth: boolean): Promise<EFTInstrumentDetailsRs> {
    let errorHeapId = isAuth? 'MMA-View-NotificationSystemError|GetInstrumentDetailsEFT' : 'MMA-View-NotificationSystemError|GuestPayment|GetInstrumentDetailsEFT';
    const path = this.location.path();
    const getEFTEndpoint = isAuth? Endpoints.api.getPaymentInstrumentDetailsEFT : Endpoints.api.getGuestPaymentInstrumentDetailsEFT;

    this.getInstrumentDetailsEFTObs = this.http.post<EFTInstrumentDetailsResponse>(getEFTEndpoint, instrumentIdRq, { withCredentials: true }).pipe(share());

    return new Promise((resolve, reject) => {
      this.getInstrumentDetailsEFTObs.subscribe({
        next: (response: EFTInstrumentDetailsResponse) => {
          this.requestCache.cacheBust(getEFTEndpoint);

          if (response && response.result && response.result.status === HttpStatusCode.Ok) {
            resolve(response.result);   
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: response.result.status });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  async makeCreditCardPayments(paymentsRq: MakePaymentsRq, isAuth: boolean): Promise<BaseMakePaymentsRs> {
    const errorHeapId = isAuth ? 'MMA-View-NotificationSystemError|MakeCCPayments' : 'MMA-View-NotificationSystemError|GuestPayment|MakeCCPayments';
    const path = this.location.path();
    const makeCreditCardPaymentsEndpoint = isAuth ? Endpoints.api.makeCreditCardPayments : Endpoints.api.makeGuestCreditCardPayments;

    this.makeCreditCardPaymentsObs = this.http.post<any>(makeCreditCardPaymentsEndpoint, paymentsRq, { withCredentials: true }).pipe(share());
    return new Promise((resolve, reject) => {
      this.makeCreditCardPaymentsObs.subscribe({
        next: (response: MakePaymentsResponse) => {
          this.requestCache.cacheBust(makeCreditCardPaymentsEndpoint);

          if (response && response.result) {
            resolve(response.result);  
          } else {
            if (!isAuth) {
              const location: string = 'paymentsService.makeCCPayments|success';
              const data: string = JSON.stringify(response);
  
              this.analyticsService.trackPWOLAnalytics(location, data);
            }
            
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: response.result.status });
          }
        },
        error: (error) => {
          if (!isAuth) {
            const location: string = 'paymentsService.makeCCPayments|catch';
            const data: string = JSON.stringify(error, Object.getOwnPropertyNames(error));
  
            this.analyticsService.trackPWOLAnalytics(location, data);
          }
          
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  async updateCreditCardProfile(saveCCReq: SaveCreditCardProfileRq): Promise<SaveCreditCardRs> {
    const errorHeapId = 'MMA-View-NotificationSystemError|UpdateCreditCardProfile';
    const path = this.location.path();
    this.updateCreditCardProfileObs = this.http.post<SaveCreditCardProfileRq>(Endpoints.api.updateCreditCardProfile, saveCCReq, { withCredentials: true }).pipe(share());
    return new Promise((resolve, reject) => {
      this.updateCreditCardProfileObs.subscribe({
        next: (response: SaveCreditCardRs) => {
          if (response && response.status && response.status.code === 0) {
            resolve(response);
            this.requestCache.cacheBust(Endpoints.api.getPaymentInfoFromBillInfo);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject();
          }

          this.requestCache.cacheBust(Endpoints.api.updateCreditCardProfile);
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    })
  }

  async deleteCreditCardProfile(deleteCCReq: DeleteCreditCardProfileRq): Promise<DeleteCreditCardProfileRs> {
    const errorHeapId = 'MMA-View-NotificationSystemError|DeleteCreditCardProfile';
    const path = this.location.path();
    this.deleteCreditCardProfileObs = this.http.post<DeleteCreditCardProfileRs>(Endpoints.api.deleteCreditCardProfile, deleteCCReq, { withCredentials: true }).pipe(share());
    return new Promise((resolve, reject) => {
      this.deleteCreditCardProfileObs.subscribe({
        next: (response: DeleteCreditCardProfileRs) => {
          if (response && response.status && response.status.code === 0) {
            resolve(response);
            this.requestCache.cacheBust(Endpoints.api.getPaymentInfoFromBillInfo);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject();
          }

          this.requestCache.cacheBust(Endpoints.api.deleteCreditCardProfile);
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  async scheduleMakePayments(scheduleMakePaymentsRq : ScheduleMakePaymentRq): Promise<ScheduleMakePaymentRs> {
    const errorHeapId = 'MMA-View-NotificationSystemError|ScheduleMakePayments'
    const path = this.location.path();
    const scheduleMakePaymentEndpoint =  Endpoints.api.scheduleMakePayments;
    this.makeCreditCardPaymentsObs = this.http.post<any>(scheduleMakePaymentEndpoint, scheduleMakePaymentsRq, { withCredentials: true }).pipe(share());
    return new Promise((resolve, reject) => {
      this.makeCreditCardPaymentsObs.subscribe({
        next: (response: ScheduleMakePaymentsRs) => {
          this.requestCache.cacheBust(scheduleMakePaymentEndpoint);
          this.requestCache.cacheBust(Endpoints.api.getScheduleMakePayments);

          if (response && response.result && response.result.status && response.result.scheduledPaymentAccounts) {
            resolve(response.result);  
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: response.result.status });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  async makeEFTPayments(makePaymentsRq: MakePaymentsRq, isAuth: boolean): Promise<MakeEFTPaymentsRs> {
    const errorHeapId = isAuth ? 'MMA-View-NotificationSystemError|MakeEFTPayments' : 'MMA-View-NotificationSystemError|GuestPayment|MakeEFTPayments';
    const path = this.location.path();
    const makeEFTPaymentsEndpoint = isAuth ? Endpoints.api.makeEFTPayments : Endpoints.api.makeGuestEftPayments;

    this.makeEFTPaymentsObs = this.http.post<MakeEFTPaymentsResponse>(makeEFTPaymentsEndpoint, makePaymentsRq, { withCredentials: true }).pipe(share());

    return new Promise((resolve, reject) => {
      this.makeEFTPaymentsObs.subscribe({
        next: (response: MakeEFTPaymentsResponse) => {
          this.requestCache.cacheBust(makeEFTPaymentsEndpoint);
          if (response && response.result) {
            resolve(response.result);
          } else {
            if (!isAuth) {
              const location: string = 'paymentsService.makeEFTPayments|success';
              const data: string = JSON.stringify(response);

              this.analyticsService.trackPWOLAnalytics(location, data);
            }
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: response.result });
          }
        },
        error: (error) => {
          if (!isAuth) {
            const location: string = 'paymentsService.makeEFTPayments|catch';
            const data: string = JSON.stringify(error, Object.getOwnPropertyNames(error));

            this.analyticsService.trackPWOLAnalytics(location, data);
          }
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }
  
  async getScheduleMakePayments(): Promise<ScheduleMakePaymentRs> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetScheduleMakePayments'
    const path = this.location.path();
    const getScheduleMakePaymentEndpoint =  Endpoints.api.getScheduleMakePayments;
    this.getScheduledMakePaymentObs = this.http.get<ScheduleMakePaymentRs>(getScheduleMakePaymentEndpoint, { withCredentials: true }).pipe(share());
    return new Promise((resolve, reject) => {
      this.getScheduledMakePaymentObs.subscribe({
        next: (response: ScheduleMakePaymentsRs) => {
          if (response && response.result && response.result.status && response.result.scheduledPaymentAccounts) {
            resolve(response.result);  
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            this.requestCache.cacheBust(Endpoints.api.getScheduleMakePayments);
            reject({ error: response.result.status });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          this.requestCache.cacheBust(Endpoints.api.getScheduleMakePayments);
          reject({ error: error });
        }
      });
    });
  }

  async getScheduleMakePaymentsForBilling(billingAccountNumber : string): Promise<ScheduleMakePaymentRs> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetScheduleMakePayments'
    const path = this.location.path();
    const getScheduleMakePaymentEndpoint =  Endpoints.api.getScheduleMakePaymentsForBillingAccount(billingAccountNumber);
    this.getScheduledMakePaymentObs = this.http.get<ScheduleMakePaymentRs>(getScheduleMakePaymentEndpoint, { withCredentials: true }).pipe(share());
    return new Promise((resolve, reject) => {
      this.getScheduledMakePaymentObs.subscribe({
        next: (response: ScheduleMakePaymentsRs) => {
          this.requestCache.cacheBust(Endpoints.api.getScheduleMakePaymentsForBillingAccount(billingAccountNumber));

          if (response && response.result && response.result.status && response.result.scheduledPaymentAccounts) {
            resolve(response.result);  
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: response.result.status });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  async cancelScheduledPayments(billingAccountNumber: string, cancelScheduledPaymentRq : CancelScheduledPaymentsRq): Promise<ScheduleMakePaymentRs> {
    const errorHeapId = 'MMA-View-NotificationSystemError|CancelScheduledPayments'
    const path = this.location.path();
    const cancelScheduledPaymentsEndpoint =  Endpoints.api.cancelScheduledPayments(billingAccountNumber);
    this.cancelScheduledPaymentsObs = this.http.post<any>(cancelScheduledPaymentsEndpoint, cancelScheduledPaymentRq, {withCredentials: true }).pipe(share());

    return new Promise((resolve, reject) => {
      this.cancelScheduledPaymentsObs.subscribe({
        next: (response: ScheduleMakePaymentsRs) => {
          this.requestCache.cacheBust(cancelScheduledPaymentsEndpoint);
          this.requestCache.cacheBust(Endpoints.api.getScheduleMakePayments);
          this.requestCache.cacheBust(Endpoints.api.getBillingHistory);
          this.billingService.ngForceUnsubscribe('billingHistory');

          if (response && response.result && response.result.status && response.result.scheduledPaymentAccounts) {
            resolve(response.result);  
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: response.result.status });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

}

export class InitiatePaymentInstrumentRs {
  result: InitiateInstrument;
  status: ApiResponseStatus;
}

export class EFTInstrumentDetailsResponse {
  result: EFTInstrumentDetailsRs;
}

export class CreditCardInstrumentDetailsResponse {
  result: CreditCardInstrumentDetailsRs;
}

export class MakePaymentsResponse {
  result: BaseMakePaymentsRs;
}

export class MakeEFTPaymentsResponse {
  result: MakeEFTPaymentsRs;
}

export class SaveCreditCardRs {
  result: SaveCreditCardProfileRs;
  status: ApiResponseStatus;
}

export class ScheduleMakePaymentsRs{
 result:ScheduleMakePaymentRs;
 status: ApiResponseStatus;
}
