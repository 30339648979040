import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { share, mergeMap } from 'rxjs/operators';

import { CommonService } from '../common-service/common.service';
import EsignSearch from '../../models/esignSearch';
import { EsignSessionRequest, EsignSessionUrls } from '../../models/esignSessionUrls';
import Endpoints from 'src/app/utilities/constants/endpoints';
import { Alert, AlertType } from '../../models/alert';
import { Location } from '@angular/common';
import Policy from '../../models/policy';
import EsignSessionPolicy from '../../models/esignSessionPolicy';
import { eSignStatusRs } from '../../models/eSign';
import { ApiResponseStatus } from '../../models/apiResponseStatus';
import { EsignStatus, Platform } from '../../constants/esign';

@Injectable({
  providedIn: 'root'
})
export class EsignService {
  esignSearchResult: EsignSearch = null;
  esignSearchObs: Observable<EsignSearchResponse> = null;
  esignSessionResult: EsignSessionUrls = null;
  esignSessionObs: Observable<EsignSessionResponse> = null;
  esignStatusObs: Observable<eSignStatusResponse> = null;
  esignForkJoinResponse: EsignSessionPolicy[] = null;
  esignForkJoinResponseObs: Observable<EsignSearch[]> = null;
  esignRenewalForkJoinResponseObs: Observable<EsignSearch[]> = null;
  esignAllForkJoinResponseObs: Observable<{ esign: EsignSearch[]; esignRenewal: EsignSearch[]; }> = null;
  needToSignStatus = ['activated', 'created', 'in_progress', 'sent'];
  isEsignApiLaunched: boolean;
  isEsignSearchSessionApiLaunched: boolean;

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private location: Location
  ) {
  }

  async getEsignStatus(): Promise<eSignStatusRs> {
    let errorHeapId = 'MMA-View-NotificationSystemError|getEsignStatus';
    const path = this.location.path();
    this.isEsignApiLaunched = this.commonService.features?.esign_status_enabled;

    if (this.isEsignApiLaunched) {
      this.esignStatusObs = this.http.post<eSignStatusResponse>(Endpoints.cloudApi.esign, { withCredentials: true }).pipe(share());
    }
    else {
      this.esignStatusObs = this.http.get<eSignStatusResponse>(Endpoints.api.getEsignStatus, { withCredentials: true }).pipe(share());
    }
    const esignRespCode: number = this.isEsignApiLaunched ? 200 : 0;

    return new Promise((resolve, reject) => {
      this.esignStatusObs.subscribe({
        next: (response: eSignStatusResponse) => {
          if (response && response.status && response.status.code === esignRespCode) {
            if (response.result) {
              resolve(response.result);
            }
          } else {
            reject({ error: response.status });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  getSearchResult(policyNumber: string, isRenewalEsign: boolean): Promise<EsignSearch> {
    this.isEsignSearchSessionApiLaunched = this.commonService.features?.esign_searchandsession_enabled;
    let endpoint = Endpoints.api.getEsignSearch(policyNumber, isRenewalEsign);
    if (this.isEsignSearchSessionApiLaunched) {
      endpoint = Endpoints.cloudApi.esignSearch(policyNumber, isRenewalEsign);
    }

    this.esignSearchObs = this.http.get<EsignSearchResponse>(endpoint, { withCredentials: true }).pipe(share());
    const errorAlert = new Alert(AlertType.NEGATIVE);
    errorAlert.messages = ['Oh dear, something went wrong. Please check back soon to sign your policy.'];
    errorAlert.heapId = 'MMA-View-NotificationSystemError|GetEsignSearchResult';
    const path = this.location.path();

    return new Promise((resolve, reject) => {
      this.esignSearchObs.subscribe({
        next: (response: EsignSearchResponse) => {
          if (response.result !== null) {
            this.esignSearchResult = response.result;
            resolve(this.esignSearchResult);
          } else {
            resolve(null);
          }
        },
        error: (error) => {
          this.commonService.pushAlert(errorAlert, path);
          reject({ error: error });
        }
      });
    });
  }

  getSessionUrls(esignSessionRq: EsignSessionRequest): Promise<EsignSessionUrls> {
    this.isEsignSearchSessionApiLaunched = this.commonService.features?.esign_searchandsession_enabled;
    let endpoint = Endpoints.api.getEsignSessionUrls(esignSessionRq.sessionId);
    if (this.isEsignSearchSessionApiLaunched) {
      endpoint = Endpoints.cloudApi.esignSessionUrls(esignSessionRq.sessionId);
    }
    this.esignSessionObs = this.http.post<EsignSessionResponse>(endpoint, esignSessionRq, { withCredentials: true }).pipe(share());
    const errorAlert = new Alert(AlertType.NEGATIVE);
    errorAlert.messages = ['Oh dear, something went wrong. Please check back soon to sign your policy.'];
    errorAlert.heapId = 'MMA-View-NotificationSystemError|GetEsignSessionUrls';
    const path = this.location.path();

    return new Promise((resolve, reject) => {
      this.esignSessionObs.subscribe({
        next: (response: EsignSessionResponse) => {
          if (response.result !== null) {
            this.esignSessionResult = response.result;
            resolve(this.esignSessionResult);
          } else {
            resolve(null);
          }
        },
        error: (error) => {
          this.commonService.pushAlert(errorAlert, path);
          reject({ error: error });
        }
      });
    });
  }


  // Get esign policies by checking for all active policies 
  // Call both NB/endorsement and renewal esign endpoints
  async getAllEsignPolicies(policies: Policy[]): Promise<EsignSessionPolicy[]> {
    const esignSessionPolicies: EsignSessionPolicy[] = [];
    policies = policies.filter((policy: Policy) => !policy.isCanceled);

    if (policies.length > 0) {
      const esignSearchPromise = (policy: Policy) => this.getSearchResult(policy.number, false); // NB and endorsement
      const esignRenewalSearchPromise = (policy: Policy) => this.getSearchResult(policy.number, true); // renewal
      const policyNumbersObservable = of(policies);

      if (!this.esignAllForkJoinResponseObs) {
        this.esignAllForkJoinResponseObs = policyNumbersObservable.pipe(
          mergeMap(
            policy => forkJoin({
              esign: forkJoin(policy.map(esignSearchPromise)),
              esignRenewal: forkJoin(policy.map(esignRenewalSearchPromise))
            })
          )).pipe(share());
      }

      return new Promise((resolve, reject) => {
        this.esignAllForkJoinResponseObs.subscribe(searchResults => {
          let results = searchResults.esign.concat(searchResults.esignRenewal); //combine the 2 arrays
          results.forEach((searchResponse, i) => {
            if (searchResponse !== null) {
              for (const session of searchResponse.esignSessions) {
                if (~this.needToSignStatus.indexOf(session.status)) {
                  const completeEsignPolicy = new EsignSessionPolicy(policies[i], session, null);
                  esignSessionPolicies.push(completeEsignPolicy);
                }
              }
            }
            resolve(esignSessionPolicies);
          },
            (error) => {
              reject({ error: error });
            });
        });
      });
    } else {
      return new Promise((resolve) => {
        resolve(esignSessionPolicies);
      });
    }
  }

  /*
  async getEsignPolicies(policies: Policy[]): Promise < EsignSessionPolicy[] > {
      const esignSessionPolicies: EsignSessionPolicy[] = [];

      if(policies.length > 0) {
      const esignSearchPromise = (policy: Policy) => this.getSearchResult(policy.number, false);
      const policyNumbersObservable = of(policies);
      if (!this.esignForkJoinResponseObs) {
        this.esignForkJoinResponseObs = policyNumbersObservable.pipe(mergeMap(policy => forkJoin(policy.map(esignSearchPromise)))).pipe(share());
      }
      return new Promise((resolve, reject) => {
        this.esignForkJoinResponseObs.subscribe(searchResults => {
          searchResults.forEach((searchResponse, i) => {
            if (searchResponse !== null) {
              for (const session of searchResponse.esignSessions) {
                if (~this.needToSignStatus.indexOf(session.status)) {
                  const completeEsignPolicy = new EsignSessionPolicy(policies[i], session, null);
                  esignSessionPolicies.push(completeEsignPolicy);
                }
              }
            }
            resolve(esignSessionPolicies);
          },
            (error) => {
              reject({ error: error });
            });
        });
      });
    } else {
      return new Promise((resolve) => {
        resolve(esignSessionPolicies);
      });
    }
  } */

  async getDocusignPolicies() {
    let docusignStatuses = [];
    try {
      const esignStatuses = (await this.getEsignStatus()).esignStatuses;
      docusignStatuses = esignStatuses.filter(status =>
        status.eSignInfo.infoDetails !== null && status.eSignInfo.infoDetails.find(detail => detail.platform === Platform.DOCUSIGN && detail.eSignStatus === EsignStatus.PENDING)
      );
    } catch (err) {
      // continue regardless of error
    }
    return docusignStatuses;
  }

}

class EsignSearchResponse {
  result: EsignSearch;
  status: {
    code: number;
  };
}

class EsignSessionResponse {
  result: EsignSessionUrls;
  status: {
    code: number;
  };
}

export class eSignStatusResponse {
  result: eSignStatusRs;
  status: ApiResponseStatus;
}
