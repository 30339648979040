import { checkPolicyDocumentAvailability, setPolicyDocumentDescription } from 'src/app/utilities/constants/policyDocumentUtility';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, Inject, Renderer2, ChangeDetectorRef, Input } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Cookies } from 'src/app/utilities/services/cookies-service/cookies.service';
import { DOCUMENT } from '@angular/common';
import { environment as env } from 'src/environments/environment';
import { EmailService } from 'src/app/utilities/services/email-service/email.service';
import { EmailTemplate } from 'src/app/utilities/models/email';
import { Features } from 'src/app/utilities/models/features';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { CrossSellService } from 'src/app/utilities/services/cross-sell-service/cross.sell.service';
import { ModalService } from 'src/app/utilities/services/modal-service/modal.service';
import { PaperlessService } from 'src/app/utilities/services/paperless-service/paperless.service';
import { SlackService } from 'src/app/utilities/services/slack-service/slack.service';
import { RoutingService } from 'src/app/utilities/services/routing-service/routing.service';
import { Subscription } from 'rxjs';
import Endpoints from 'src/app/utilities/constants/endpoints';
import { crossSellDisclaimer, crossSellModalHeader, crossSellModalBody, } from 'src/app/utilities/constants/crossSellDisclaimer';
import PolicyDocument, { DocumentPolicies } from 'src/app/utilities/models/policyDocument';
import CrossSellOffer, { Dispositions } from 'src/app/utilities/models/crossSellOffer';
import CAMStorage from 'src/app/utilities/constants/CAMStorage';
import { LOBName } from 'src/app/utilities/constants/linesOfBusiness';
import { Alert, AlertType } from 'src/app/utilities/models/alert';
import { PostponePaymentModalInfo } from 'src/app/utilities/models/modal';
import DateMethods from 'src/app/utilities/methods/date.methods';
import { PreferencesService } from 'src/app/utilities/services/preferences-service/preferences.service';
import Preferences from 'src/app/utilities/models/preferences';
import { PreferenceKeys } from 'src/app/utilities/constants/preferences';
import { ModalNames } from 'src/app/utilities/constants/modalNames';
import { Color } from 'src/app/utilities/constants/colorNames';
import { CancelScheduledPaymentsRq, ScheduleMakePaymentRs } from 'src/app/utilities/models/payments';
import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import { DocumentService } from 'src/app/utilities/services/document-service/document.service';
import { PaymentsService } from 'src/app/utilities/services/payments-service/payments.service';
import ScheduledPaymentsMethods from 'src/app/utilities/methods/scheduledPayments.methods';
import { EmailTypes } from 'src/app/utilities/constants/policyChange';
import { AccountService } from 'src/app/utilities/services/account-service/account.service';
import { SCHEDULE_MAKE_PAYMENT_STATUS_CODES } from 'src/app/utilities/constants/payment';
import { ConfirmServiceType, ContentType, PaperlessModalAnalytics, ToolName } from 'src/app/utilities/constants/analytics';
import { PaperlessDocumentStatusCodes, PaperlessDocuments } from 'src/app/utilities/models/paperlessDocuments';
import PaperlessBilling from 'src/app/utilities/models/paperlessBilling';
import { PaperlessPolicy } from 'src/app/utilities/models/paperlessPolicy';
import { BookTransferInfo } from 'src/app/utilities/models/bookTransfer';
import { ReviewBillingSchedule } from 'src/app/utilities/models/autoPay';
import OverrideExperience from 'src/app/utilities/models/overrideExperience';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {

  @ViewChild('modalHiddenStart', { static: true }) modalHiddenStart: ElementRef;
  @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;

  @Input() paperlessTermsAndConditions: string;

  agencyEmail: string;
  agencyName: string;
  agencyPhone: string;
  analyticsBillingPolicyList: string = '';
  analyticsPolicyList: string = '';
  autopaySuccessMessage: string;
  bookTransferInfo: BookTransferInfo;
  color: any = Color;
  customerName: string = '';
  document: Document;
  emailAddress: string = '';
  features: Features = new Features();
  goldPhoneNumber: string;
  imgLoc: string = env.imgLoc;
  isBillingAvailable = false;
  isMobileFullScreenEnabled: boolean;
  isGoldAgency: boolean;
  isGoldPlusAgency: boolean;
  isModalActionRequired: boolean;
  isModalContentScrollEnabled: boolean;                 // only content is scrollable, CTAs like buttons stay fixed to the bottom of the modal
  isModalLoaded: boolean = false;
  isModalLoadedSubscription: Subscription;
  isModalSmall: boolean = false;
  isPaperlessUpdateInProgress: boolean = false;
  isPolicyDocumentsAvailable: boolean = false;
  isRequestInProgress: boolean = false;
  isScrollEnabled: boolean = false;                     // entire modal is scrollable, including buttons
  isPolicyTransferredFromStateAuto: boolean = false;
  caPaperlessLaunched: boolean; // leaving in here so it can be reused for CAPaperless re-enrollment modal
  exitSurveyTextArea: string = '';
  LOB: any = LOBName;
  mobileQuery: MediaQueryList;
  modalName: string = '';
  modalNames: any = ModalNames;
  offer: CrossSellOffer;
  offerLob: string;
  offerRs: CrossSellOffer;
  offerSavings: number;
  paymentSettingsSuccessMessages: string[];
  policyDocuments: PolicyDocument = null;
  policyDocumentsSubscription: Subscription;
  postponePaymentSuccessInfo: PostponePaymentModalInfo;
  reviewAutopayUnenrollSchedule: ReviewBillingSchedule[];
  shouldCompleteCloseModalAction: boolean = true;
  showModalErrorActionBadge: boolean;
  showVerifyRemoveSpinner: boolean = false;
  showCloseButton: boolean = false;
  modalActionErrorBadgeText: string = "Please make a selection before continuing.";
  sourceComponent: string = '';
  sourcePolicyLob: string;
  url: any = Endpoints.url;
  paperlessModalTitleText: string = "Good news! Your policy documents and bills are now paperless.";
  paperlessModalParagraphText: string = "You’ll get an email notification when you have new documents and bills ready to view. Access them any time online.";
  paperlessTermsTitle: string = "Please review the Safeco Paperless Terms and Conditions";
  verifyPolicyNumber: string;
  xSellBody: string;
  xSellDisclaimer: string;
  xSellHeader: string;
  userPreferences: Preferences;
  scheduledPaymentToCancel: CancelScheduledPaymentsRq;
  cancelScheduledPaymentAccountNumber: string;
  cancelScheduledPaymentPolicies: string;
  override: OverrideExperience;

  PaperlessEnrollmentErrorMessage: any = {
    ERROR_TITLE: "Your paperless settings didn't save. ",
    ERROR_ALERT_MESSAGE: `Please try { "text" : "changing your paperless", "url" : "${this.url.paperlessOptions}", "queryParams" : "${this.url.paperlessOptionsParams}" } preferences again. `,
    ERROR_ALERT_INLINE: false,
  };

  constructor(
    private accountService: AccountService,
    private analyticsService: AnalyticsService,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    private cookies: Cookies,
    private crossSellService: CrossSellService,
    private emailService: EmailService,
    private documentService: DocumentService,
    private media: MediaMatcher,
    private modalService: ModalService,
    private paperlessService: PaperlessService,
    private paymentsService: PaymentsService,
    private preferencesService: PreferencesService,
    private _renderer: Renderer2,
    private slackService: SlackService,
    public routingService: RoutingService,
    @Inject(DOCUMENT) document
  ) {
    this.document = document;
    this.override = new OverrideExperience();
  }

  ngOnInit() {
    this.setModalName();
    this.setModalSourceComponent(this.modalService.getSourceComponent());
    this.modalService.setCanDeactivate(false);
    this.isScrollEnabled = false;

    switch (this.modalName) {
      case ModalNames.CROSS_SELL:
        this.openCrossSellModal();
        break;
      case ModalNames.POLICY_DOCUMENTS:
        this.setPolicyDocumentsModalSubscriptions();
        this.configurePolicyDocumentsModal();
        break;
      case ModalNames.PAPERLESS:
      case ModalNames.PAPERLESS_CA:
      case ModalNames.PAPERLESS_CA_REENROLL:
        this.isScrollEnabled = true;
        this.isMobileFullScreenEnabled = true;
        this.isModalActionRequired = true;
        this.modalActionErrorBadgeText = "Please select a paperless option.";
        this.getUserPreferences();
        this.getBillingAvailableFlag();
        this.setCustomerInfo();
        break;
      case ModalNames.UPDATE_AUTOPAY_SUCCESS:
        this.autopaySuccessMessage = this.modalService.autoPaySuccessMessage;
        this.bookTransferInfo = this.modalService.bookTransferInfo;
        break;
      case ModalNames.POSTPONE_PAYMENT_SUCCESS:
        this.postponePaymentSuccessInfo = this.modalService.postponePaymentSuccessInfo;
        this.bookTransferInfo = this.modalService.bookTransferInfo;
        break;
      case ModalNames.CC_AUTHORIZATION:
        this.isScrollEnabled = true;
        break;
      case ModalNames.VERIFY_REMOVE_POLICY:
        this.verifyPolicyNumber = this.modalService.verifyPolicyNumber;
        break;
      case ModalNames.PAYMENT_SETTINGS_SUCCESS:
        this.paymentSettingsSuccessMessages = this.modalService.paymentSettingsSuccessMessages;
        this.bookTransferInfo = this.modalService.bookTransferInfo;
        break;
      case ModalNames.SMS_SIGNUP:
        this.isMobileFullScreenEnabled = true;
        break;
      case ModalNames.CANCEL_SCHEDULE_PAYMENT:
        this.scheduledPaymentToCancel = this.modalService.scheduledPaymentToCancel;
        this.cancelScheduledPaymentAccountNumber = this.modalService.cancelScheduledPaymentAccountNumber;
        this.cancelScheduledPaymentPolicies = this.modalService.cancelScheduledPaymentPolicies;
        break;
      case ModalNames.REVIEW_AUTOPAY_UNENROLL:
        this.isMobileFullScreenEnabled = true;
        this.isModalContentScrollEnabled = true;
        this.reviewAutopayUnenrollSchedule = this.modalService.reviewAutopayUnenrollSchedule;
        break;
      default:
        break;
    }

    this.setModalFocus();
    this.showCloseButton = this.setShowCloseButton();
    this.displayCloseButtonAtTopOfModal();
    this.getFeatures();

    // Angular CDK Media Matcher to display different elements based on the screen size responsively - added for Paperless modal
    this.mobileQuery = this.media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    // to prevent scroll on the screen behind when the modal is open, so that only scrolling is on the modal
    this._renderer.setStyle(document.body, 'overflow', 'hidden');

    if (this.showCloseButton) {
      this.commonService.scrollTo("top-close-button-container");
    }
  }

  private _mobileQueryListener: () => void;

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      if (!this.isModalActionRequired) {
        this.closeModal();
      } else {
        this.showModalErrorActionBadge = true;
      }
    } 
  }

  setModalFocus() {
    this.modalHiddenStart.nativeElement.focus();   
  }

  
  setCustomerInfo() {
    const authUser = this.accountService.getAuthUser();

    if (authUser) {
      this.customerName = authUser.name ? authUser.name : '';
      this.emailAddress = authUser.email ? authUser.email : '';
    }
  }

  ngOnDestroy() {
    // add scroll back to body
    this._renderer.removeStyle(document.body, 'overflow');
    switch (this.modalName) {
      case ModalNames.POLICY_DOCUMENTS:
        this.isModalLoadedSubscription.unsubscribe();
        this.policyDocumentsSubscription.unsubscribe();
        break;
    }

    // remove mobile media matcher listener
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  async getFeatures() {
    try {
      this.features = await this.commonService.getFeatures();
      if (this.features) {
        // leaving in here so it can be reused for CAPaperless re-enrollment modal
        this.caPaperlessLaunched = this.features.caPaperlessLaunched;
      }
    } catch (error) {
      console.log(error);
    }
  }

  setModalName(): void {
    this.modalName = this.modalService.getModalName();
  }

  setModalSourceComponent(sourceComponent: string): void {
    this.sourceComponent = sourceComponent;
  }

  checkModalFocus($event) {
    const targetClassName: string = ($event && $event.target?.className) ? $event.target.className : "";

    if (targetClassName && targetClassName === 'modal-overlay-dark') {
      if (!this.isModalActionRequired) {
        this.closeModal();
      } else {
        this.showModalErrorActionBadge = true;
      }
    }
  }

  closeModal(eventAnalyticsId = ''): void {
    if (this.shouldCompleteCloseModalAction) {
      this.completeModalActionOnClose();
    }

    if (eventAnalyticsId) {
      this.analyticsService.trackAnalytics(eventAnalyticsId);
    }

    this.modalService.closeModal();

    const calledFromElement: HTMLElement = this.document.getElementById(this.modalName);
    if (calledFromElement) {
      calledFromElement.focus();
    }
  }
  
  async setSurveyResponse(response: boolean) {
    if (response) {
      this.isRequestInProgress = true;

      try {
        await this.slackService.sendExitSurveyRequest(this.exitSurveyTextArea);
      } catch (error) {
        console.log(error);
      } finally {
        this.isRequestInProgress = false;
      }
    }

    this.modalService.setCanDeactivate(true);
    this.modalService.closeModal();
  }

  disableSurveySubmitButton(): boolean {
    return this.isRequestInProgress || !this.exitSurveyTextArea || !this.exitSurveyTextArea.trim().length;
  }

  contactAgent(action: boolean): void {
    this.closeModal();
    this.modalService.contactAgent(action);
  }

  setShowCloseButton(): boolean {
    switch (this.modalName) {
      case ModalNames.UPDATE_AUTOPAY_SUCCESS:
      case ModalNames.POLICY_DOCUMENTS:
      case ModalNames.PAPERLESS:
      case ModalNames.PAPERLESS_CA:
      case ModalNames.PAPERLESS_CA_REENROLL:
      case ModalNames.VERIFY_REMOVE_POLICY:
      case ModalNames.REVIEW_AUTOPAY_UNENROLL:
        return false;
      default:
        return true;
    }
  }
  
  displayCloseButtonAtTopOfModal() {
    // if the close X is displayed then set flex-direction to column-reversed so that it will display at the top of the modal
    if(this.showCloseButton) {
      // flex-direction set to column-reversed on app_modal will default modal webkit scrollbar to the bottom
      this._renderer.setStyle(this.document.getElementById('app_modal'), 'flex-direction', 'column-reverse');      
    }
  }

  setPolicyDocumentsModalSubscriptions(): void {
    this.isModalLoadedSubscription = this.modalService.isModalLoadedObs.subscribe((isModalLoaded: boolean) => this.isModalLoaded = isModalLoaded);
    this.policyDocumentsSubscription = this.modalService.policyDocumentsObs.subscribe(
      (policyDocuments: PolicyDocument) => {
        this.policyDocuments = policyDocuments;
        this.isPolicyDocumentsAvailable = checkPolicyDocumentAvailability(this.policyDocuments);
      }
    );
  }

  configurePolicyDocumentsModal(): void {
    this.isModalLoaded = this.modalService.isModalLoaded;
    this.policyDocuments = this.modalService.policyDocuments;
    this.isPolicyDocumentsAvailable = checkPolicyDocumentAvailability(this.policyDocuments);
  }

  getPolicyDocumentDescription(policyDocument: DocumentPolicies): string {
    return setPolicyDocumentDescription(policyDocument);
  }

  openPolicyDocument(policyDocument: DocumentPolicies): void {
    const policyDocUrl: string = policyDocument.url[0] === '/' ? policyDocument.url.substring(1) : policyDocument.url;
    const url: string = env.domain + policyDocUrl;
    this.routingService.openInNewWindow(url);
  }

  async goPaperlessLink(paperlessPreference: string) {
    const isPreferencesLaunched = this.features && this.features.preferencesLaunched;
    const enrollPaperless: boolean = (paperlessPreference === 'Y') ? true : false;

    if (!isPreferencesLaunched || !this.userPreferences) {
      this.cookies.setPaperlessPolicyCookie(paperlessPreference);
    }
    
    if (enrollPaperless) {
      this.isPaperlessUpdateInProgress = true;
      let response = await this.paperlessService.updateAllPaperlessPoliciesAndBilling(true);
      this.setPaperlessTandCsAlert(response);
      this.sendPaperlessAnalytics(response);
      
    } 

    // update preferences
    if (isPreferencesLaunched) {
      this.updatePaperlessPreferences(enrollPaperless);
    } 

    this.isPaperlessUpdateInProgress = false;
    this.modalService.closeModal();
  }

  // CA Re-enrollment Modal
  // Set policies' paperless prefs to Y
  // Store Paperless T&C document
  async reenrollPaperless() {
    // get array of policy numbers to reenroll in paperless from local storage
    const policyNumbersCAReenroll = this.getPoliciesNumbersForReenroll();
    
    this.isPaperlessUpdateInProgress = true;
    if(Array.isArray(policyNumbersCAReenroll) && policyNumbersCAReenroll.length) {
      let response = await this.paperlessService.updateReenrollPaperlessSpecificPolicies(policyNumbersCAReenroll);
      this.setPaperlessTandCsAlert(response);
      this.documentService.resetPolicyDocumentsQLStore(); // reset policy documents paperless status call
      
      // update preferences to enrolled
      this.updatePaperlessPreferences(true);
    } else { // policyNumbersCAReenroll is not an array, undefined, null OR empty, show error alert
      const failureHeapId = 'MMA-View-NotificationSystemError|PaperlessTandCsForm';
      const alert = this.createAlert(AlertType.NEGATIVE, this.PaperlessEnrollmentErrorMessage.ERROR_TITLE, this.PaperlessEnrollmentErrorMessage.ERROR_ALERT_MESSAGE, 
        this.PaperlessEnrollmentErrorMessage.ERROR_ALERT_INLINE, failureHeapId);
      this.commonService.pushAlert(alert);
    }

    this.isPaperlessUpdateInProgress = false;
    this.modalService.closeModal();
  }

  // CA Re-enrollment Modal helper
  // get compliance policy numbers from local storage
  private getPoliciesNumbersForReenroll() : string[] {
      let reenrollPoliciesList = [];
      try {
        if(window.localStorage.getItem('reenrollPolicies')) {
          let reenrollPoliciesStr = window.localStorage.getItem('reenrollPolicies');
          if (reenrollPoliciesStr) {
            reenrollPoliciesStr = reenrollPoliciesStr.substring(0, reenrollPoliciesStr.length - 1); // remove comma on end of string
            reenrollPoliciesList = reenrollPoliciesStr.split(",");
          }
          window.localStorage.removeItem('reenrollPolicies');
          return reenrollPoliciesList;
        } else { // reenrollPolicies not set in local storage or "" empty string
          window.localStorage.removeItem('reenrollPolicies');
          return reenrollPoliciesList;
        }
      } 
      catch {
        window.localStorage.removeItem('reenrollPolicies');
        // continue if errors - if error won't reenroll but customer would see the paperless reenroll pop on next login
        return reenrollPoliciesList;
      }
    }

  // CA Re-enrollment Modal
  // Set policies' paperless to N 
  async mailDocuments() {
    this.isPaperlessUpdateInProgress = true;

    let response = await this.paperlessService.updateAllPaperlessPoliciesAndBilling(false);
    this.updatePaperlessPreferences(false);
    this.sendPaperlessAnalytics(response);

    this.isPaperlessUpdateInProgress = false;
    this.modalService.closeModal();
  }

  getBillingAvailableFlag() {
    this.isBillingAvailable = this.modalService.isBillingAvailable;
  }

  getUserPreferences() {
    this.userPreferences = this.preferencesService.userPreferences;
  }

  updatePaperlessPreferences(enrollPaperless: boolean) {
    if (enrollPaperless) {
      this.preferencesService.setUserPreference(PreferenceKeys.IS_PAPERLESS_ENROLLED, true);
    } else {
      const paperlessDismissedDate = DateMethods.formatDateString(new Date());
      this.preferencesService.setUserPreference(PreferenceKeys.PAPERLESS_DISMISSED, paperlessDismissedDate);
    } 

    this.updatePreferences();
  }

  updateSmsOptInPreferences() {
    const smsDismissedDate = DateMethods.formatDateString(new Date());
    this.preferencesService.setUserPreference(PreferenceKeys.SMS_OPT_IN_DISMISSED, smsDismissedDate);
    this.updatePreferences();
  }

  updatePreferences(): void {
    try {
      this.preferencesService.updatePreferences();
    } catch (err) {
      // continue regardless of error
    }
  }

  async openCrossSellModal() {
    this.offer = CAMStorage.getItemInStorage(CAMStorage.storageKeys.crossSellStorage, true);
    this.offer = (this.override?.infoCard)
      ? {...this.offer, savings: parseInt((Math.random() * 100).toString()), lobOffer: this.override.infoCardLob }
      : this.offer;
    if (this.offer) {
      this.setCrossSellContent();
      this.crossSellService.postCrossSellDispositionQL(this.offer, Dispositions.clicked);
      this.contactAgencyWithCrossSell();
    }
  }

  setCrossSellContent() {
    this.sourcePolicyLob = this.offer?.sourceLob?.toLowerCase();
    this.offerLob = this.offer?.lobOffer?.toLowerCase();
    this.setCrossSellDisclaimer(this.offer.lobOffer);
    this.offerSavings = this.offer.savings;
    this.agencyName = this.offer?.agency.agencyName;
    this.agencyPhone = this.offer?.agency.agencyPhone;
    this.agencyEmail = this.offer?.agency.agencyEmail;
    this.isGoldAgency = this.offer?.agency.goldAgency;
    this.isGoldPlusAgency = this.offer?.agency.goldPlusAgency;
    if (this.isGoldAgency) {
      this.goldPhoneNumber = crossSellDisclaimer.GOLD_PHONE;
    }
    if (this.isGoldPlusAgency) {
      this.goldPhoneNumber = crossSellDisclaimer.GOLD_PLUS_PHONE;
    }
    this.setCrossSellModalContent(this.offer);
  }

  async contactAgencyWithCrossSell() {  
    // build basic email object
    const xSell = new EmailTemplate(this.offer.agency.agencyEmail)
    xSell.emailTemplateData.changeType = EmailTypes.XSELL;
    xSell.emailTemplateData.lineOfBusiness = this.offerLob;
    xSell.emailTemplateData.agencyEmail = this.offer.agency.agencyEmail;
    xSell.emailTemplateData.toEmailAddress = this.offer.agency.agencyEmail;
    xSell.emailTemplateData.policyNumber = this.offer.sourcePolicyNumber;
    xSell.emailTemplateData.name = this.offer.customer.firstName + ' ' + this.offer.customer.lastName;
    xSell.emailTemplateData.customerEmail = this.offer.customer.email;
    xSell.emailTemplateData.customerPhone = this.offer.customer.phone;

    if (!this.isGoldAgency && !this.isGoldPlusAgency) {
        await this.emailService.sendBasicEmail(xSell);
    }
  }

  setCrossSellModalContent(offer: CrossSellOffer) {
    switch (offer.lobOffer) {
      case LOBName.auto:
        this.xSellHeader = crossSellModalHeader.AUTO;
        this.xSellBody = crossSellModalBody.AUTO(this.sourcePolicyLob, this.offerLob, this.offerSavings);
        break;
      case LOBName.home:
        this.xSellHeader = crossSellModalHeader.HOME;
        this.xSellBody = crossSellModalBody.HOME(this.sourcePolicyLob, this.offerLob, this.offerSavings);
        break;
      case LOBName.watercraft:
        this.xSellHeader = crossSellModalHeader.WATERCRAFT;
        this.xSellBody = crossSellModalBody.WATERCRAFT;
        this.offerLob = 'boat';
        break;
      case LOBName.motorcycle:
        this.xSellHeader = crossSellModalHeader.MOTORCYCLE;
        this.xSellBody = crossSellModalBody.MOTORCYCLE;
        break;
      case LOBName.earthquake:
        this.xSellHeader = crossSellModalHeader.EARTHQUAKE;
        this.xSellBody = crossSellModalBody.EARTHQUAKE;
        break;
      case LOBName.umbrella:
        this.xSellHeader = crossSellModalHeader.UMBRELLA;
        this.xSellBody = crossSellModalBody.UMBRELLA;
        break;
      case LOBName.renters:
        this.xSellHeader = crossSellModalHeader.RENTERS;
        this.xSellBody = crossSellModalBody.RENTERS;
        break;
      default:
        break;
    }
  }

  setCrossSellDisclaimer(lobOffer: string) {
    switch (lobOffer) {
      case LOBName.auto:
        this.xSellDisclaimer = crossSellDisclaimer.AUTO_DEFAULT;
        break;
      case LOBName.home:
        this.xSellDisclaimer = crossSellDisclaimer.HOME_DEFAULT;
        break;
      case LOBName.earthquake:
      case LOBName.motorcycle:
      case LOBName.renters:
      case LOBName.umbrella:
      case LOBName.watercraft:
        this.xSellDisclaimer = crossSellDisclaimer.GENERIC_DEFAULT;
        break;
      default:
        break;
    }
  }

  async cancelSchedulePayment(): Promise<void> {
    this.analyticsService.trackAnalytics('popUp_btn_cancelSchedulePayment');
    this.analyticsService.trackServiceAnalytics(ToolName.CANCEL_SCHEDULED_PAYMENT, ConfirmServiceType.COMPLETE, this.cancelScheduledPaymentPolicies, ToolName.CANCEL_SCHEDULED_PAYMENT, ContentType.MODAL);

    let isSuccess = false;

    try {
      this.isRequestInProgress = true;
      const paymentsRs: ScheduleMakePaymentRs = await this.paymentsService.cancelScheduledPayments(this.cancelScheduledPaymentAccountNumber, this.scheduledPaymentToCancel);

      if (paymentsRs && paymentsRs.status) {
        if (paymentsRs.status == SCHEDULE_MAKE_PAYMENT_STATUS_CODES.success) {
          isSuccess = true;
        } else {
          this.analyticsService.trackErrorAnalytics(paymentsRs.status.toString());
        }
      } 
    } catch (error) {
      console.log(error);
      const errorString = (error && error.toString()) ? error.toString() : "Cancel SAP error";          // default message to track for analytics
      this.analyticsService.trackErrorAnalytics(errorString);
    } finally {
      
      isSuccess = await this.validateCanceledScheduledPayments(this.cancelScheduledPaymentAccountNumber, this.scheduledPaymentToCancel);

      this.modalService.setHasCancelScheduledPaymentBeenCall();
      this.modalService.setCancelScheduledPaymentSuccess(isSuccess);
      this.isRequestInProgress = false;
    }

    this.closeModal();
  }

  async validateCanceledScheduledPayments(billingAccountNumber: string , scheduledPaymentToCancel: CancelScheduledPaymentsRq): Promise<boolean> {
    let isSuccess = false
    
    try {
      const scheduledPayments = await this.paymentsService.getScheduleMakePayments();

      if (scheduledPayments && scheduledPayments.scheduledPaymentAccounts && scheduledPayments.scheduledPaymentAccounts.length) {
        const scheduledPaymentsForBilling = scheduledPayments.scheduledPaymentAccounts.find( sap => sap.accountNumber == billingAccountNumber);
        if (scheduledPaymentsForBilling) {
          isSuccess = scheduledPaymentToCancel.scheduledPayments.every(sap => !ScheduledPaymentsMethods.hasClientPaymentId(scheduledPaymentsForBilling, sap.clientPaymentId));
        }
      }
    } catch (error) {
      console.log(error)
    }

    return isSuccess;
  }

  confirmAutopayUnenrollment(): void {
    this.analyticsService.trackAnalytics("Autopay_popUp_btn_confirmUnenroll");
    this.modalService.setUnenrollAutopayFlag(true);
    this.closeModal();
  }

  checkAutopayUnenrollAction(): void {
    if (!this.modalService.unenrollAutopay) {
      this.modalService.setUnenrollAutopayFlag(false);
    }
  }

  navigateToTextAlerts() {
    this.shouldCompleteCloseModalAction = false;
    this.routingService.routeTo(this.url.textAlerts, {queryParams: this.url.textAlertsParams});
    this.closeModal();
  }

  completeModalActionOnClose() {
    switch (this.modalName) {
      case ModalNames.SMS_SIGNUP:
        this.updateSmsOptInPreferences();
        break;
      case ModalNames.REVIEW_AUTOPAY_UNENROLL:
        this.checkAutopayUnenrollAction();
        break;
      default:
        break;
    }
  }

  // create and set success or error alert
  // no alert for successful t&cs save with failed paperless policy update
  // WIP - going to check combination of statusCode and description when service is ready; using statusCode check for UI testing
  setPaperlessTandCsAlert(response: PaperlessDocuments) {
    let alert = null;
    let failureHeapId = 'MMA-View-NotificationSystemError|PaperlessTandCsForm';
    if (response.status.statusCode == PaperlessDocumentStatusCodes.exception) { // failed
      alert = this.createAlert(AlertType.NEGATIVE, this.PaperlessEnrollmentErrorMessage.ERROR_TITLE, this.PaperlessEnrollmentErrorMessage.ERROR_ALERT_MESSAGE, 
        this.PaperlessEnrollmentErrorMessage.ERROR_ALERT_INLINE, failureHeapId);
    } else if(response.tandCStatusCode == PaperlessDocumentStatusCodes.failureInTAndCStorageRequest) { 
      alert = this.createAlert(AlertType.NEGATIVE, this.PaperlessEnrollmentErrorMessage.ERROR_TITLE, this.PaperlessEnrollmentErrorMessage.ERROR_ALERT_MESSAGE, 
        this.PaperlessEnrollmentErrorMessage.ERROR_ALERT_INLINE, failureHeapId);
    } else if(response.status.statusCode == PaperlessDocumentStatusCodes.incompleteInformationInRequest) {
      alert = this.createAlert(AlertType.NEGATIVE, this.PaperlessEnrollmentErrorMessage.ERROR_TITLE, this.PaperlessEnrollmentErrorMessage.ERROR_ALERT_MESSAGE, 
        this.PaperlessEnrollmentErrorMessage.ERROR_ALERT_INLINE, failureHeapId);
    } else if (response.status.statusCode == PaperlessDocumentStatusCodes.success) { // success
      // will need to change how display notifications work in common.service displayNotifications()
    }

    if (alert) {
      this.commonService.pushAlert(alert);
    }
  }

  sendPaperlessAnalytics(response: PaperlessDocuments) {
    let modalContentType = '';
    let optOption = PaperlessModalAnalytics.OPT_IN;
    switch (this.modalName) {
      case ModalNames.PAPERLESS:
        modalContentType = PaperlessModalAnalytics.MODAL_NONCA;
        break;
      case ModalNames.PAPERLESS_CA:
        modalContentType = PaperlessModalAnalytics.MODAL_CA;
        break;
      case ModalNames.PAPERLESS_CA_REENROLL:
        modalContentType = PaperlessModalAnalytics.MODAL_REENROLL;
        optOption = PaperlessModalAnalytics.OPT_OUT;
        break;
      default:
        break;
    }

    let isPageViewEvent = false; // button and link click events
    if (response.status.statusCode == PaperlessDocumentStatusCodes.success) {
      this.buildAnalyticsBillingPolicyList(response.paperlessBilling);
      this.buildAnalyticsPolicyList(response.paperlessPolicies);
      // send opt in policy docs analytics
      if (response.paperlessPolicies && response.paperlessPolicies.length > 0) {
        this.analyticsService.trackPaperlessModalAnalytics(PaperlessModalAnalytics.TILE_NAME, this.analyticsPolicyList, isPageViewEvent,
          PaperlessModalAnalytics.CONTENT_TYPE_MODAL, modalContentType, optOption, PaperlessModalAnalytics.DOC_TYPE_POLICY);      
      }
      // send opt in billing statements analytics
      if (response.paperlessBilling && response.paperlessBilling.length > 0) {
        this.analyticsService.trackPaperlessModalAnalytics(PaperlessModalAnalytics.TILE_NAME, this.analyticsBillingPolicyList, isPageViewEvent,
          PaperlessModalAnalytics.CONTENT_TYPE_MODAL, modalContentType, optOption, PaperlessModalAnalytics.DOC_TYPE_BILLING);      
      }
    } 
  }
  
  private buildAnalyticsBillingPolicyList(accounts: PaperlessBilling[]) {
    accounts.forEach(account => {
      account.policyNumbers.forEach(policy => {
        if (!this.analyticsBillingPolicyList.includes(policy)) {
          this.analyticsBillingPolicyList += policy + ',';
        }
      });
    });
  }

  private buildAnalyticsPolicyList(policies: PaperlessPolicy[] ) {
    policies.forEach(policy => {
      if (!this.analyticsPolicyList.includes(policy.policyNumber)) {
        this.analyticsPolicyList += policy.policyNumber + ',';
      }
    });
  }

  createAlert(type: AlertType, title: string, message: string, isInline: boolean, heapId: string): Alert {
    const confirmationAlert = new Alert(type);
    confirmationAlert.title = title;
    confirmationAlert.heapId = heapId;
    confirmationAlert.inline = isInline;
    confirmationAlert.noTitleCase = true;
    confirmationAlert.messages.push(message);
  
    return confirmationAlert;
  }

}
