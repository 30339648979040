import { gql } from 'apollo-angular';
import { DocumentsMetadataResponse, DocumentVariables, DocumentUrlResponse, DocumentUrlVariables } from 'src/app/utilities/models/documentMetadata';

export const GET_DOCUMENTS = gql<DocumentsMetadataResponse, DocumentVariables> `query
GetDocumentMetadata(
  $policyNumbers: [String!]
  $category: DocCategory
) {
  getDocumentMetadata (
    policies: $policyNumbers,
    clientType: SERVICE_CUSTOMER
    brand: SAFECO
    docCategory: $category
  ) {
    documentGroup {
      policyNumber
      hasPaperlessDocs
      primaryDocuments {
        billingAccountNumber
        lineOfBusiness
        policyEffectiveDate
        policyExpirationDate
        transactionEffectiveDate
        policyNumber
        creationDate
        docTypeCode
        jurisdiction
        effectiveDate
        displayName
        docTypeName
        objectId
        documentUID
        newDocument
        currentDocument
        contentUrl
        documentType
        receivedPassDate
        documentTypeDescription
      }
      priorDocuments {
        billingAccountNumber
        lineOfBusiness
        policyEffectiveDate
        policyExpirationDate
        transactionEffectiveDate
        policyNumber
        creationDate
        docTypeCode
        jurisdiction
        effectiveDate
        displayName
        docTypeName
        objectId
        documentUID
        newDocument
        currentDocument
        contentUrl
        documentType
        receivedPassDate
        documentTypeDescription
      }
    }
  }
}`;

export const GET_DOCUMENT_URL = gql<DocumentUrlResponse, DocumentUrlVariables> `query 
GetDocumentURL(
  $documentId: String!
  $tenant: String!
  $category: DocCategory
) {
  getDocumentURL(
    documentId: $documentId
    clientType: SERVICE_CUSTOMER
    tenant: $tenant
    brand: SAFECO
    docCategory: $category
  ) {
    downloadUrl
  }
}`;
