export const DocumentStatusMsg = {
  DEFAULT_UNAVAILABLE: '',
  ID_CARDS_UNAVAILABLE: 'We are having trouble getting your ID cards right now. You can use these ID cards for 30 days or try again later.',
  ID_CARDS_IN_PROGRESS: 'Give us a few days, and we\'ll have your standard ID cards ready. Use these in the meantime to provide proof of insurance — these cards expire in 30 days.',
  POLICY_DOCS_UNAVAILABLE: ''
}

export const DocumentCodes = {
  BillingStatementReg: 'G40',
  BillingStatementReft: 'G42',
  BillingStatementPd: 'G43',
  BillingStatementRcc: 'G44',
  WelcomePacket: 'PM1',
  PolicyChange: 'PM4',
  Renewal: 'PM5',
  Inquiry: 'PM6',
  IDCards: 'PM7',
  Cancellation: 'PM8',
  Reinstatement: 'PM9',
  CancellationNotice: 'PM30', //T12 and IA correlates to this
  AntiTheft: 'PM47',
  ConsentToRate: 'PM66',
  CoverageOption: 'PM71',
  CoverageSelection: 'PM72',
  Discounts: 'PM75',
  DriverRestriction: 'PM76',
  DriverTraining: 'PM77',
  ElectronicFundsTransfer: 'PM80',
  GoodStudent: 'PM89',
  LeadPaintCert: 'PM99',
  MiscEndorsement: 'PM113',
  MoldCoverage: 'PM114',
  NonRenewalLetter: 'PM120', //T15 & T16 correlates to this
  PIPCoverage: 'PM123',
  TortOption: 'PM161',
  UMUIMCoverage: 'PM164',
  VehicleInspection: 'PM172',
  PropertyInspection: 'PM184',
  EarthquakeCoverageOffer: 'PM194',
  //CertificatOfInsurance: 'PM195',
  DocuSignCertOfCompletion: 'PM263',
  CancellationNonPay: 'IA',
  CancellationUW: 'T12',
  NonRenewalAgt: 'T15',
  NonRenewalUW: 'T16'
};

export const DocumentDisplayNames = {
  BillingStatementReg: 'Billing statement',
  BillingStatementReft: 'Billing statement',
  BillingStatementPd: 'Billing statement',
  BillingStatementRcc: 'Billing statement',
  WelcomePacket: 'Welcome packet',
  PolicyChange: 'Policy change',
  Renewal: 'Renewal',
  Inquiry: 'Inquiry',
  IDCards: 'ID cards',
  Cancellation: 'Cancellation',
  Reinstatement: 'Reinstatement',
  CancellationNotice: 'Cancellation notice',
  AntiTheft: 'Anti-theft',
  ConsentToRate: 'Consent to rate',
  CoverageOption: 'Coverage option',
  CoverageSelection: 'Coverage selection',
  Discounts: 'Discounts',
  DriverRestriction: 'Driver restriction',
  DriverTraining: 'Driver training',
  ElectronicFundsTransfer: 'Electronic funds transfer',
  GoodStudent: 'Good student',
  LeadPaintCert: 'Lead paint certificate',
  MiscEndorsement: 'Misc endorsement',
  MoldCoverage: 'Mold coverage',
  NonRenewalLetter: 'Non-renewal letter',
  PIPCoverage: 'PIP coverage',
  TortOption: 'Tort option',
  UMUIMCoverage: 'UM UIM coverage',
  VehicleInspection: 'Vehicle inspection',
  PropertyInspection: 'Property inspection',
  EarthquakeCoverageOffer: 'Earthquake coverage offer',
  //CertificatOfInsurance: 'Certificate of insurance',
  DocuSignCertOfCompletion: 'Document signing certificate of completion',
  CancellationNonPay: 'Cancellation notice or returned payment notice',
  CancellationUW: 'Underwriting cancellation',
  NonRenewalAgt: 'Non-renewal letter',
  NonRenewalUW: 'Non-renewal letter'
};

export const DocumentDescriptions = {
  BillingStatementReg: 'This form contains recent transactions, the current amount, and other billing information.',
  BillingStatementReft: 'This form contains recent transactions, the current amount, and other billing information.',
  BillingStatementPd: 'This form contains recent transactions, the current amount, and other billing information.',
  BillingStatementRcc: 'This form contains recent transactions, the current amount, and other billing information.',
  WelcomePacket: 'Welcome to Safeco! This document contains the details of your new policy, coverages, and personal information.',
  PolicyChange: 'This document details a recent change made to your policy, like an added vehicle, driver, or coverage.',
  Renewal: 'This is your renewal policy information. You’ll see your new rates, coverages, and other information to know for your next year.',
  Inquiry: 'This has everything you need. It’s all of your current policy information, as well as all of the legal documentation that came with your original policy.',
  IDCards: 'This card serves as proof of insurance, containing your personal and policy information. Keep one in your glove box.',
  Cancellation: 'This document outlines the details of your policy cancellation.',
  Reinstatement: 'This document contains details of your policy being reinstated.',
  CancellationNotice: 'This is your notice that your policy will be cancelled.',
  AntiTheft: 'Find information regarding the anti-theft features in your vehicle that qualify for a discount.',
  ConsentToRate: 'This form provides your consent in the event an insurance company needs to alter a policy rate.',
  CoverageOption: 'Review information explaining your state’s coverage options and your possible coverage selections.',
  CoverageSelection: 'Review information explaining your state’s coverage options and your possible coverage selections.',
  Discounts: 'Find information about discounts that may be applied to your policy.',
  DriverRestriction: 'Find information here about your state law that prohibits any drivers from being on the road without insurance.',
  DriverTraining: 'This contains information about a recent driver training course completed by a driver on your policy.',
  ElectronicFundsTransfer: 'Details of how your funds are withdrawn electronically from your personal financial institution.',
  GoodStudent: 'This document contains the details of your Good Student Discount. Keep up the good work!',
  LeadPaintCert: 'Contains information regarding your certification to handle lead-based paint.',
  MiscEndorsement: 'This document details a recent change made to your policy, like an added vehicle, driver, or coverage.',
  MoldCoverage: 'This document outlines the specifics of your coverage for mold-related damage to your home.',
  NonRenewalLetter: 'This document contains details on your policy not being renewed.',
  PIPCoverage: 'Learn more about your Personal Injury Protection coverage, a portion of your policy that covers medical expenses, regardless of who’s at fault.',
  TortOption: 'Learn more about your options regarding compensation and legal rights as a result of being in a not-at-fault accident.',
  UMUIMCoverage: 'This coverage protects you for injuries sustained in an accident caused by someone with insufficient insurance.',
  VehicleInspection: 'Find information about your vehicle’s inspection in this document.',
  PropertyInspection: 'This document contains information about recent inspections or reports on your property.',
  EarthquakeCoverageOffer: 'This document outlines the provision of supplemental earthquake coverage.',
  DocuSignCertOfCompletion: 'This document shows the details of your recent eSignature completion using Docusign.',
  CancellationNonPay: 'This document contains the details of your cancellation notice or your returned payment notice.',
  CancellationUW: 'This document contains the details of your underwriting cancellation notice.',
  NonRenewalAgt: 'This document contains the details of your non-renewal notice.',
  NonRenewalUW: 'This document contains the details of your non-renewal notice.'
};
