import { inject, NgModule } from '@angular/core';
import { provideNamedApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
 
const camApiGraphQL = environment.camApiGraphQL;
const appTroveGraphQL = environment.appTroveGraphQL;

@NgModule({
  providers: [
    provideNamedApollo(() => {
      const httpLink = inject(HttpLink);
     
      return {
        //  These settings will be saved as default client
        default: {
          link: httpLink.create({
            uri: camApiGraphQL,
          }), 
          cache: new InMemoryCache({
            resultCaching: true,
          }),
        },
        // These settings will be saved by name: appTroveGraphQl
        appTroveGraphQl: {
          link: httpLink.create({
            uri: appTroveGraphQL,
          }),
          cache: new InMemoryCache({
            resultCaching: true,
          }),
        },
      };
    }),
  ]
})

export class GraphQLModule {}